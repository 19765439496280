
/* MDCRadiobutton
 * Usage: <mdc-radiobutton id="uniqueId" name="radiogroup" value="valueofchoice" v-bind="choosenvalue">TextOnRadiobutton</mdc-radiobutton>
 * Id should be supplied to keep label and input connection
 * If you set the choosenvalue v-bind value that is selected in the radiogroup by default. Leave empty to not select any value.
 * If you supply disabled attribute <mdc-radiobutton disabled>TextOnRadiobutton</mdc-radiobutton> the input will be disabled
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "mdcRadiobutton",
  props: {
    group: {
      type: String,
      default: "radios",
    },
    id: {
      type: String,
      default: "checkbox-1",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(_props, context) {
    function updateValue(event: MouseEvent & { target: HTMLInputElement }) {
      context.emit("update:modelValue", event.target.value);
    }

    return { updateValue };
  },
});
