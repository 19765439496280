
/* MDCDialog (Floating action button)
 * Usage: <mdc-dialog>favorite</mdc-dialog> and <mdc-dialog icon="favorite"></mdc-dialog>
 * Iconname can be supplied either by attribute or text inside tags. https://material.io/resources/icons for valid icon names.
 * The button supports three modes (default, mini, extended). Default is default style if omitted.
 * When using extended type a labeltext should be supplied
 * <mdc-dialog type="extended" label="Add to cart">add</mdc-dialog>
 * 
 * //TODO:
 * Ett sätt att hindra dialogen att stänga om man vill göra custom close. Ta bort data-mdc-dialog-action från button. En ny via v-if?
 * dialog_submit.addEventListener('click', () => {
    // if (there is text in the text area) {
        // dialog.close();
    // } else {
        // Give user error message and don't close the dialog
    // }
});
 *  
 */
import { defineComponent, ref, onMounted } from "vue";
import { MDCDialog } from "@material/dialog";

interface detail {
  action: string;
}

export default defineComponent({
  name: "mdcDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    cancel: {
      type: String,
      default: null,
    },
    ok: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const inputDialog = ref(null);
    let inputDialogObj: MDCDialog;

    const open = () => {
      inputDialogObj.open();
    };

    onMounted(() => {
      inputDialogObj = new MDCDialog(inputDialog.value);
      inputDialogObj.listen(
        "MDCDialog:closed",
        (action: CustomEvent & { detail: detail }) => {
          context.emit("action", action.detail.action);
          // if (
          //   action.detail.action === "close" ||
          //   action.detail.action === "cancel"
          // ) {
          //   return;
          // } else {
          //   return;
          // }
        }
      );
    });

    return { inputDialog, open };
  },
});
