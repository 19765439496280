
/* MDCButton
 * Usage: <mdc-button>TextOnButton</mdc-button>
 * If you supply icon attribute <mdc-button icon="favorite">TextOnButton</mdc-button> an icon will be placed beginning of text
 * If you supply type (outlined, raised, unelevated) it will change the apperence of the button. Raised will be used by default
 * <mdc-button type="outlined" icon="favorite">TextOnButton</mdc-button>
 */
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "mdcButton",
  props: {
    type: {
      type: String,
      default: "raised",
    },
    icon: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    let buttonType = ref("");
    //type = outlined, raised, unelevated
    if (
      props.type === "outlined" ||
      props.type === "raised" ||
      props.type === "unelevated"
    ) {
      buttonType.value = "mdc-button--" + props.type;
    } else {
      buttonType.value = "mdc-button--raised";
    }

    return { buttonType };
  },
});
