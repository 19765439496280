
/* MDCIconButton
 * Usage: <mdc-icon-button>favorite</mdc-icon-button> and <mdc-icon-button icon="favorite"></mdc-icon-button>
 * Iconname can be supplied either by attribute or text inside tags. https://material.io/resources/icons for valid icon names.
 * When iconOn is supplied toggle mode is enabled on the button
 * When passing defaultOn the button is initialized in state "On"
 * <mdc-icon-button icon="favorite_border" iconOn="favorite" defaultOn></mdc-icon-button>
 */
import { onMounted, defineComponent, ref } from "vue";
import { MDCIconButtonToggle } from "@material/icon-button";

export default defineComponent({
  name: "mdcIconButton",
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconOn: {
      type: String,
      default: null,
    },
    defaultOn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const refIconButton = ref(null);
    const mdcIconButtonOn = ref("");

    let iconToggle: MDCIconButtonToggle;

    if (props.defaultOn === true) {
      mdcIconButtonOn.value = "mdc-icon-button--on";
    }

    onMounted(() => {
      if (props.iconOn) {
        const iconToggle = new MDCIconButtonToggle(refIconButton.value);
      }
    });

    return { refIconButton, mdcIconButtonOn };
  },
});
