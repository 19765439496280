<template>
  <h2>MDC Components</h2>
  <h3>mdcSnackbar</h3>
  <!-- <mdc-snackbar
    class="my-snackbar"
    ref="refSnackbar"
    buttonlabel="OK"
    @reason="dialogevent($event)"
    >Bar is open!</mdc-snackbar
  > -->
  <mdc-button @click="opensnackbar">Snacks?</mdc-button>
  <h3>mdcSwitch</h3>
  <mdc-switch style="width: 300px" id="switch1" v-model="switch_1"
    >Rör mig</mdc-switch
  >
  <mdc-switch style="width: 300px" id="switch2" disabled>Rör ej!</mdc-switch>
  <p class="margin">{{ switch_1 }}</p>
  <h3>mdcRadiobutton</h3>
  <mdc-radiobutton id="radio-1" v-model="radioChoice" value="choice1" checked
    >Choice 1</mdc-radiobutton
  >
  <mdc-radiobutton id="radio-2" v-model="radioChoice" value="choice2"
    >Choice 2</mdc-radiobutton
  >
  <p class="margin">{{ radioChoice }}</p>
  <h3>mdcCheckbox</h3>
  <mdc-checkbox id="checkboxtest-1" v-model="checked"
    >Test checkbox {{ checked }}
  </mdc-checkbox>
  <h3>mdcDialog</h3>
  <mdc-dialog
    ref="exampleDialog"
    title="Dagens sanning"
    ok="Tack"
    @action="dialogevent($event)"
  >
    Du är bäst!
  </mdc-dialog>
  <mdc-button class="margin" @click="opendialog">Öppna dialog</mdc-button>
  <h3>mdcInputText</h3>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    label="E-mail"
    helpertext="Email du vill registrera dig med"
    v-model="stremail"
    pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
    validationtext="Måste vara en giltig e-post adress"
  ></mdc-input-text>
  <p class="margin">{{ stremail }}</p>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    label="Poäng"
    inputtype="number"
    v-model="intNumber"
    :min="7"
    :max="20"
    validationtext="Värdet måste vara mellan 7 och 20"
  ></mdc-input-text>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    inputtype="password"
    pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$"
  ></mdc-input-text>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    type="label-floating"
    label="Your input"
    value="example"
    :maxlength="20"
  ></mdc-input-text>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    type="disabled"
    label="Can't touch me!"
  ></mdc-input-text>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    icon="phone"
    trailingicon="clear"
    label="Phone number"
    required
    @trailingIconClick="inputclick"
  ></mdc-input-text>
  <mdc-input-text
    class="margin"
    style="width: 300px"
    trailingicon="content_copy"
    label="Beskrivning"
    @trailingIconClick="copyclick"
  ></mdc-input-text>
  <h3>mdcInputDate</h3>
  <mdc-input-date
    label="Födelsedag"
    class="margin"
    style="width: 300px"
    min="1900-01-01"
    max="2999-12-31"
    inputtype="date"
    v-model="inputDate"
    helpertext="Din födelsedag!"
  ></mdc-input-date>
  {{ inputDate }}
  <mdc-input-date
    label="Datum & tid"
    class="margin"
    style="width: 300px"
    min="2021-01-01T00:00"
    max="2999-12-31T00:00"
    inputtype="datetime-local"
    helpertext="Datum och tid för tävling"
    validationtext="Måste vara större än 1/1"
    v-model="inputDateTime"
  ></mdc-input-date>
  {{ inputDateTime }}
  <mdc-input-date
    label="Födelsedag"
    class="margin"
    style="width: 300px"
    type="disabled"
    min="1900-01-01"
    max="2999-12-31"
    inputtype="date"
    v-model="inputDate"
  ></mdc-input-date>
  <h3>mdcIndicator</h3>
  <mdc-indicator></mdc-indicator>
  <h3>mdcButton</h3>
  <mdc-button class="margin">raised</mdc-button>
  <mdc-button class="margin" icon="favorite" @click="setLoading(true)"
    >Button with icon</mdc-button
  >
  <mdc-button class="margin" type="unelevated" @click="setLoading(false)"
    >unelevated</mdc-button
  >
  <mdc-button class="margin" type="outlined" @click="increment"
    >outlined</mdc-button
  >
  <h3>mdcIconButton</h3>
  <mdc-icon-button>favorite</mdc-icon-button>
  <mdc-icon-button
    icon="favorite_border"
    iconOn="favorite"
    defaultOn
  ></mdc-icon-button>
  <h3>mdcFab</h3>
  <mdc-fab class="margin">favorite</mdc-fab>
  <mdc-fab class="margin" type="extended" label="Add to cart">add</mdc-fab>
  <h3>mdcDataTable</h3>
  <mdc-data-table
    :rowsPerPage="rowsPerPage"
    :columns="tableColumns"
    :rows="tableRows"
  ></mdc-data-table>
  <h3>mdcSelect</h3>
  <mdc-select :items="select" v-model="selectChoice"
    >Pick a Food Group</mdc-select
  >
  <br />{{ selectChoice }}
  <h3>mdcList</h3>
  <mdc-list :items="data"></mdc-list>
  <mdc-list type="icon-list" :items="data"></mdc-list>
  <mdc-list type="avatar-list" :items="data"></mdc-list>
  <mdc-list type="two-line icon-list" :items="data"></mdc-list>
  <mdc-list type="two-line avatar-list" :items="data"></mdc-list>
  <mdc-list type="dense" :items="data"></mdc-list>
  <mdc-list type="dense router-link" :items="data"></mdc-list>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "mdcTest",
  setup(props, context) {
    const store = useStore();
    const exampleDialog = ref(null);
    const checked = ref(true);
    const radioChoice = ref("choice2");
    const selectChoice = ref("grains");
    const stremail = ref("");
    const switch_1 = ref(true);
    const refSnackbar = ref(null);
    const intNumber = ref(7);
    const inputDate = ref(null);
    const inputDateTime = ref(null);

    function opensnackbar() {
      store.dispatch("createSnack", {labelText: "Bar is open!", actionButtonText: "Great" });
      // refSnackbar.value.open();
    }

    function setLoading(value: boolean) {
      store.commit("setLoading", value);
    }

    function dialogevent(action: string) {
      console.log(action);
    }

    function increment() {
      store.dispatch("loadNews");
    }

    function inputclick(inputText: HTMLInputElement) {
      inputText.value = "";
      inputText.focus();
    }

    function copyclick(inputText: HTMLInputElement) {
      console.log(inputText);
      inputText.select();
      inputText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      //inputText.focus();
    }

    function opendialog() {
      exampleDialog.value.open();
    }
    const select = [
      {
        key: "1",
        text: "Bread, Cereal, Rice, and Pasta",
        value: "grains",
      },
      {
        key: "2",
        text: "Vegetables",
        value: "vegetables",
      },
      {
        key: "3",
        text: "Fruit",
        value: "fruit",
      },
    ];

    const rowsPerPage = [
      {
        key: "1",
        text: "10",
        value: 10,
      },
      {
        key: "2",
        text: "50",
        value: 50,
      },
      {
        key: "3",
        text: "100",
        value: 100,
      },
            {
        key: "4",
        text: "1000",
        value: 1000,
      },
    ];

    const tableColumns = [
      {
        label: "Id",
        id: "id",
        type: "numeric",
        sortable: true,
        key: true,
        display: null,
      },
      {
        label: "Name",
        id: "name",
        sortable: true,
        key: false,
        display: function(row) {
          return (
            '<a href="#" data-id="' +
            row.id +
            '" class="name-btn">' +
            row.name +
            "</button>"
          );
        },
      },
      {
        label: "Email",
        id: "email",
        sortable: false,
        key: false,
        display: function(row) {
          return (
            '<a href="#" data-id="' +
            row.id +
            '" class="name-btn">' +
            row.name +
            "</button>"
          );
        },
      },
    ];

    const sampleData1 = (offst: number, limit: number) => {
      offst = offst + 1;
      let data = [];
      for (let i = offst; i <= limit; i++) {
        data.push({
          id: i,
          name: "TEST"+Math.round(i/10),
          email: "test" + i + "@example.com",
        });
      }
      return data;
    };
    const tableRows = sampleData1(0, 200);

    const data = [
      {
        key: "1",
        text: "Text for list",
        text2: "Text for line 2",
        icon: "favorite",
        imageUrl: "/img/icons/android-chrome-192x192.png",
        disabled: false,
        selected: false,
        activated: false,
        divider: false,
        href: "/",
      },
      {
        key: "2",
        text: "",
        text2: "",
        icon: "",
        imageUrl: "",
        disabled: false,
        selected: false,
        activated: false,
        divider: true,
      },
      {
        key: "3",
        text: "Text for list",
        text2: "Text for line 2",
        icon: "favorite",
        imageUrl: "/img/icons/android-chrome-192x192.png",
        disabled: false,
        selected: false,
        activated: false,
        divider: false,
        href: "/landing",
      },
    ];
    return {
      data,
      select,
      setLoading,
      increment,
      inputclick,
      exampleDialog,
      opendialog,
      dialogevent,
      checked,
      stremail,
      radioChoice,
      switch_1,
      opensnackbar,
      refSnackbar,
      intNumber,
      inputDate,
      inputDateTime,
      copyclick,
      selectChoice,
      rowsPerPage,
      tableColumns,
      tableRows,
    };
  },
});
</script>
<style scoped>
.margin {
  margin: 10px;
}
</style>
