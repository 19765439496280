<template>
  <div class="inline-text-field-container">
    <label
      ref="refInputText"
      class="mdc-text-field mdc-text-field--outlined"
      :class="mdcInputType"
    >
      <span class="mdc-notched-outline">
        <span class="mdc-notched-outline__leading"></span>
        <span class="mdc-notched-outline__notch">
          <span
            v-if="label"
            class="mdc-floating-label"
            :class="mdcFloatingLabel"
            >{{ label }}</span
          >
        </span>
        <span class="mdc-notched-outline__trailing"></span>
      </span>
      <i
        v-if="icon"
        class="material-icons mdc-text-field__icon mdc-text-field__icon--leading"
        >{{ icon }}</i
      >
      <span v-if="type.includes('textarea')" class="mdc-text-field__resizer">
        <textarea
          class="mdc-text-field__input"
          :rows="rows"
          :cols="cols"
          aria-label="Label"
          :disabled="type.includes('disabled')"
          :maxLength="maxLength"
        ></textarea>
      </span>
      <input
        v-else
        ref="refInput"
        :type="inputtype"
        class="mdc-text-field__input"
        aria-labelledby="my-label-id"
        :value="modelValue"
        @input="updateValue"
        :disabled="type.includes('disabled')"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        :min="min"
        :max="max"
        :pattern="pattern"
      />
      <i
        v-if="trailingicon"
        class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing"
        tabindex="0"
        role="button"
        @click="trailingIconClick"
        >{{ trailingicon }}</i
      >
    </label>
    <div class="mdc-text-field-helper-line">
      <div
        v-if="maxlength"
        ref="refInputTextCounter"
        class="mdc-text-field-character-counter"
      >
        0 / {{ maxlength }}
      </div>
      <div
        v-if="mdcTextField.valid"
        class="mdc-text-field-helper-text"
        :class="{ 'mdc-text-field-helper-text--persistent': permanent }"
        aria-hidden="true"
      >
        {{ helpertext }}
      </div>
      <div
        v-if="!mdcTextField.valid"
        class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
        aria-hidden="true"
      >
        {{ validationtext }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* MDCInputText (Floating action button)
 * Usage: <mdc-input-text>favorite</mdc-input-text> and <mdc-input-text icon="favorite"></mdc-input-text>
 * Icon name can be supplied by attribute icon or trailing icon inside tags. https://material.io/resources/icons for valid icon names.
 * The trailingicon is a button and you can listen for event: trailingIconClick, it returns reference to the input of the component. eg. you can set .value = "" to clear field.
 * The input supports several types (focused,end-aligned,label-floating,with-internal-counter). Default is default style if omitted.
 * You can supply a label that sets label for input
 * If supplying pre-filled value use type label-floating to skip label animation.
 * Supported inputtype = date, datetime-local, email, month, number, password
 */
import { defineComponent, ref, onMounted } from "vue";
import { MDCTextField } from "@material/textfield";
import { MDCTextFieldCharacterCounter } from "@material/textfield/character-counter";

export default defineComponent({
  name: "mdcInputText",
  props: {
    type: {
      type: String,
      default: "default",
    },
    label: {
      type: String,
      default: null,
    },
    inputtype: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    trailingicon: {
      type: String,
      default: null,
    },
    helpertext: {
      type: String,
      default: null,
    },
    permanent: {
      type: Boolean,
      default: false,
    },
    validationtext: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    cols: {
      type: Number,
      default: 40,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  setup(props, context) {
    const refInputText = ref(null);
    const refInputTextCounter = ref(null);
    const refInput = ref(null);
    const mdcInputType = ref("");
    const mdcFloatingLabel = ref("");
    const mdcTextField = ref({ valid: true });

    let textField: MDCTextField;
    let characterCounter: MDCTextFieldCharacterCounter;

    let types: string[] = [
      "with-leading-icon",
      "with-trailing-icon",
      "no-label",
      "focused",
      "end-aligned",
      "with-internal-counter",
    ];

    function updateValue(event: MouseEvent & { target: HTMLInputElement }) {
      context.emit("update:modelValue", event.target.value);
    }
    if (props.type.includes("textarea")) {
      mdcInputType.value = "mdc-text-field--" + props.type;
    }

    types.forEach((type) => {
      if (props.type.includes(type)) {
        mdcInputType.value += " mdc-text-field--" + type;
      }
    });

    if (props.type.includes("disabled")) {
      mdcInputType.value += " mdc-text-field--disabled";
    }

    if (!props.label) {
      mdcInputType.value += " mdc-text-field--no-label";
    }

    if (props.icon) {
      mdcInputType.value += " mdc-text-field--with-leading-icon";
    }

    if (props.trailingicon) {
      mdcInputType.value += " mdc-text-field--with-trailing-icon";
    }

    if (props.type.includes("float-above")) {
      mdcFloatingLabel.value = "mdc-floating-label--float-above";
      mdcInputType.value += " mdc-text-field--label-floating";
    }

    function trailingIconClick() {
      context.emit("trailingIconClick", <HTMLInputElement>refInput.value);
    }

    onMounted(() => {
      mdcTextField.value = new MDCTextField(refInputText.value);
      characterCounter = new MDCTextFieldCharacterCounter(
        refInputTextCounter.value
      );
    });

    return {
      refInputText,
      refInputTextCounter,
      refInput,
      mdcInputType,
      mdcFloatingLabel,
      trailingIconClick,
      updateValue,
      mdcTextField,
    };
  },
});
</script>

<style scoped>
.inline-text-field-container {
  display: flex;
  flex-direction: column;
}
</style>
