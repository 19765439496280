<template>
  <div ref="refSelect" class="mdc-select mdc-select--outlined">
    <div class="mdc-select__anchor" aria-labelledby="outlined-select-label">
      <span class="mdc-notched-outline">
        <span class="mdc-notched-outline__leading"></span>
        <span class="mdc-notched-outline__notch" v-if="slots">
          <span id="outlined-select-label" class="mdc-floating-label"
            ><slot></slot
          ></span>
        </span>
        <span class="mdc-notched-outline__trailing"></span>
      </span>
      <span class="mdc-select__selected-text-container">
        <span id="demo-selected-text" class="mdc-select__selected-text"></span>
      </span>
      <span class="mdc-select__dropdown-icon">
        <svg
          class="mdc-select__dropdown-icon-graphic"
          viewBox="7 10 10 5"
          focusable="false"
        >
          <polygon
            class="mdc-select__dropdown-icon-inactive"
            stroke="none"
            fill-rule="evenodd"
            points="7 10 12 15 17 10"
          ></polygon>
          <polygon
            class="mdc-select__dropdown-icon-active"
            stroke="none"
            fill-rule="evenodd"
            points="7 15 12 10 17 15"
          ></polygon>
        </svg>
      </span>
    </div>

    <!-- Other elements from the select remain. -->
    <div class="mdc-select__menu demo-width-class mdc-menu mdc-menu-surface">
      <ul class="mdc-list">
        <li
          v-for="item in items"
          v-bind:key="item.key"
          class="mdc-list-item"
          :class="{ 'mdc-list-item--selected': item.value === modelValue }"
          :data-value="item.value"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">{{ item.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
/* MDCSelect
 * Usage: <mdc-select v-model="returnedValue" items="data">title</mdc-select>
 * If v-model has value that will be the selected value in the select
 * items contains the data for the list in the select according to MDCSelectItems
 */
import { onMounted, defineComponent, ref, PropType } from "vue";
import { MDCSelect } from "@material/select";
interface MDCSelectItems {
  key: string;
  text: string;
  value: string | number;
}
export default defineComponent({
  name: "mdcSelect",
  props: {
    items: {
      type: Object as PropType<Array<MDCSelectItems>>,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  setup(_props, context) {
    const refSelect = ref(null);
    const slots = ref(
      context.slots.default &&
        context.slots.default().findIndex((o) => o.type !== Comment) !== -1
    );

    onMounted(() => {
      const select = new MDCSelect(refSelect.value);
      select.listen("MDCSelect:change", () => {
        context.emit("update:modelValue", select.value);
      });
    });

    return { refSelect, slots };
  },
});
</script>

<style lang="sass" scoped></style>
