import { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import AppConfig from "@/config";
import HttpClientImpl from "./HttpClient";

import {
  ServerInfo,
  IAuthenticationRequest,
  IAuthenticationResponse,
  IMemberProfile,
  InfoItem,
  MenuItem,
} from "@/models";

export interface AppApi {
  setBearer(bearerToken: string): void;
  clearBearer(): void;
  hasBearer(): boolean;

  login(request: IAuthenticationRequest): Promise<any>;
  refresh(): Promise<any>;
  logout(): Promise<boolean>;
  getUserProfile(): Promise<IMemberProfile>;
  authGoogle(idtoken: string): Promise<any>;

  getServerInfo(): Promise<ServerInfo>;
  getSecretServerInfo(): Promise<ServerInfo>;

  getInfoItems(): Promise<InfoItem>;
  updateInfoItem(infoid): Promise<InfoItem>;

  getMenuItems(): Promise<MenuItem>;
}

export class AppApiImpl extends HttpClientImpl implements AppApi {
  private bearerToken = "";

  public constructor() {
    super(AppConfig.ApiUrl);
    this.initializeRequestInterceptor();
    this.initializeResoponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
  };

  private initializeResoponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse);
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    if (this.bearerToken !== "" || this.bearerToken !== undefined) {
      config.headers.common.Authorization = `Bearer ${this.bearerToken}`;
    }
    return config;
  };

  private handleResponse = ({ data }: AxiosResponse) => {
    return data;
  };

  setBearer = (bearerToken: string): void => {
    this.bearerToken = bearerToken;
  };

  clearBearer = (): void => {
    this.bearerToken = "";
  };

  hasBearer = (): boolean => {
    return this.bearerToken !== "";
  };

  login = async (
    request: IAuthenticationRequest
  ): Promise<IAuthenticationResponse> =>
    this.post<IAuthenticationRequest, IAuthenticationResponse>(
      "auth/login",
      request
    );

  refresh = async (): Promise<IAuthenticationResponse> =>
    this.post<null, IAuthenticationResponse>("auth/refresh", null);

  logout = async (): Promise<boolean> =>
    this.post<null, boolean>("auth/logout", null);

  getUserProfile = async (): Promise<IMemberProfile> =>
    this.get<IMemberProfile>("auth/profile");

  authGoogle = async (idtoken: string): Promise<IAuthenticationResponse> =>
    this.post<{ idToken: string }, IAuthenticationResponse>("auth/google", {
      idToken: idtoken,
    });

  getServerInfo = async (): Promise<ServerInfo> =>
    this.get<ServerInfo>("server/info");

  getSecretServerInfo = async (): Promise<ServerInfo> =>
    this.get<ServerInfo>("server/secret");

  getInfoItems = async (): Promise<InfoItem> =>
    this.get("http://localhost:3000/news?info_type=blog"); //    this.get("SiteContent");

  updateInfoItems = async (infoid): Promise<InfoItem> => {
    return this.put("http://localhost:3000/news/" + infoid.id, infoid); //    this.get("SiteContent");
  };

  getMenuItems = async (): Promise<MenuItem> =>
    this.get("http://localhost:3000/meta?_sort=sortKey"); // Get MenuItems for use in router/menu list

  getInfoItem = async (title: string): Promise<InfoItem> =>
    this.get("http://localhost:3000/info?title=" + title); // Get MenuItems for use in router/menu list

  createInfoItem = async (infoItem): Promise<InfoItem> =>
    this.post("http://localhost:3000/info", infoItem);

  updateInfoItem = async (infoid): Promise<InfoItem> => {
    return this.put("http://localhost:3000/info/" + infoid.id, infoid); //    this.get("SiteContent");
  };
  getCompetitions = async (): Promise<MenuItem> =>
    this.get("http://localhost:3000/competitions?_sort=datetime"); // Get MenuItems for use in router/menu list
}

const api = new AppApiImpl();
export default api;
