import { IsNotEmpty, IsEmail } from "class-validator";
import ValidationBase from "./ValidationBase";
import { IAuthenticationRequest } from "../";

export class AuthenticationRequest extends ValidationBase implements IAuthenticationRequest {

    @IsEmail(undefined, { message: "Ingen korrekt e-postadress" })
    @IsNotEmpty({ message: "E-postadress måste anges" })
    email: string | undefined;

    @IsNotEmpty({ message: "Inget lösenord angivet" })
    password: string | undefined;
}