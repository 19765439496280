<template>
  <aside
    ref="refMdcDrawer"
    class="mdc-drawer mdc-top-app-bar--fixed-adjust sidenav"
    :style="sidebarstyle"
    :class="sidebar"
  >
    <div class="mdc-drawer__header">
      <h3 class="mdc-drawer__title">Test Testson</h3>
      <h6 class="mdc-drawer__subtitle">test@test.com</h6>
    </div>
    <div class="mdc-drawer__content">
      <nav class="mdc-list mdc-list--dense">
        <slot></slot>
      </nav>
    </div>
  </aside>
  <div @click="open" class="mdc-drawer-scrim"></div>
</template>

<script lang="ts">
/* MDCDrawer
 * Usage: <mdc-drawer>other_html</mdc-drawer>
 * The drawer is visible on page if width requirement is fullfilled, if width of the screen goes below the drawer is truned into a dissmissable drawer automatically.
 * You can send open and close events to the component to open or close it.
 * <mdc-drawer type="extended" label="Add to cart">add</mdc-drawer>
 */
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { MDCDrawer } from "@material/drawer";

export default defineComponent({
  name: "mdcDrawer",
  props: {},
  setup(props) {
    const refMdcDrawer = ref(null);
    const sidebar = ref("");
    const sidebarstyle = ref({ display: "" })
    let drawer:MDCDrawer

    const open = async () => {
      if (sidebarstyle.value.display == "") {
        sidebarstyle.value.display = "flex";
        sidebar.value = " mdc-drawer--modal mdc-drawer--open";
        // await nextTick();
        // drawer = MDCDrawer.attachTo(refMdcDrawer.value);
        // console.log(drawer)
        // drawer.open = true
      } else {
        sidebarstyle.value.display = "";
        sidebar.value = "";
        // drawer.open = false
        // drawer.destroy();
      }
    };

    onMounted(() => {
      // const drawer = MDCDrawer.attachTo(refMdcDrawer.value);
    });

    return { refMdcDrawer, sidebar, open, sidebarstyle };
  },
});
</script>

<style lang="sass"></style>
