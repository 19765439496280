<template>
  <ul ref="refMdcList" class="mdc-list mdc-list-select" :class="mdcListType">
    <div v-for="(item, index) in items" v-bind:key="item.key">
      <h3 v-if="item.divider" class="mdc-list-group__subheader">
        {{ item.text }}
      </h3>
      <li v-else-if="!item.text" role="separator" class="mdc-list-divider"></li>
      <router-link
        v-else-if="type.includes('router-link')"
        :to="item.href"
        v-wave
        class="mdc-list-item"
        :tabindex="index ? -1 : 0"
      >
        <span class="mdc-list-item__ripple"></span>
        <span class="material-icons mdc-list-item__graphic" aria-hidden="true">
          {{ item.icon }}
        </span>
        <span class="mdc-list-item__text">
          {{ item.text }}
        </span>
      </router-link>
      <li v-else v-wave class="mdc-list-item" :tabindex="index ? -1 : 0">
        <span class="mdc-list-item__ripple"></span>
        <span
          v-if="type.includes('icon-list')"
          class="material-icons mdc-list-item__graphic"
          aria-hidden="true"
        >
          {{ item.icon }}
        </span>
        <img
          v-if="
            type.includes('image-list') ||
              type.includes('avatar-list') ||
              type.includes('video-list')
          "
          class="mdc-list-item__graphic"
          :src="item.imageUrl"
        />
        <span v-if="!type.includes('two-line')" class="mdc-list-item__text">
          {{ item.text }}
        </span>
        <span v-else-if="type.includes('two-line')" class="mdc-list-item__text">
          <span class="mdc-list-item__primary-text">{{ item.text }}</span>
          <span class="mdc-list-item__secondary-text">{{ item.text2 }}</span>
        </span>
        <span
          v-if="item.metaIcon"
          aria-hidden="true"
          class="mdc-list-item__meta"
        >
          <mdc-icon-button :icon="item.metaIcon"></mdc-icon-button>
        </span>
      </li>
    </div>
  </ul>
</template>

<script lang="ts">
/* MDCList
 * Usage: <mdc-list>favorite</mdc-list> and <mdc-list icon="favorite"></mdc-list>
 * The button supports multiple modes, can be array of strings for multiple options.
 * ex. (dense, textual-list, avatar-list, icon-list, image-list, thumbnail-list, video-list, two-line, router-link). Default is "mdc-list" style if omitted.
 * Iconname can be supplied by attribute in Items binding "icon" property. https://material.io/resources/icons for valid icon names.
 */
import { defineComponent, ref, PropType, onMounted } from "vue";
import { MDCList } from "@material/list";

interface MDCListItems {
  key: string;
  href?: string;
  text: string;
  text2?: string;
  icon?: string;
  imageUrl?: string;
  disabled?: boolean;
  selected?: boolean;
  activated?: boolean;
  divider?: boolean;
}

export default defineComponent({
  name: "mdcList",
  props: {
    type: {
      type: String,
      default: "",
    },
    items: {
      type: Object as PropType<MDCListItems>,
      default: null,
    },
  },
  setup(props) {
    let refMdcList = ref(null);

    onMounted(() => {
      new MDCList(refMdcList.value);
    });

    const mdcListType = ref("");

    let types: string[] = [
      "dense",
      "textual-list",
      "avatar-list",
      "icon-list",
      "image-list",
      "thumbnail-list",
      "video-list",
      "two-line",
    ];

    types.forEach((type) => {
      if (props.type.includes(type)) {
        mdcListType.value += " mdc-list--" + type;
      }
    });
    if (props.type.includes("two-line")) {
      if (props.type.includes("icon-list")) {
        mdcListType.value = "mdc-list--two-line"; //Fix for icon-lists should not have mdc-list--icon-list class, then icon is not centered on two-lines
      }
    }
    return { mdcListType, refMdcList };
  },
});
</script>

<style scoped>
.mdc-list-select {
  user-select: none;
}

.dividertext {
  margin-left: 8px;
}
</style>
