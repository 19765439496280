
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Competitions",
  setup(_props, _context) {
    const store = useStore();
    store.dispatch("loadCompetitions"); //If not loaded in onmounted the component for the indicator is not working
    const items = computed(() => store.getters.loadedCompetitions);

    onMounted(() => {});
    const tableColumns = [
      {
        label: "Datum",
        id: "datetime",
        sortable: true,
        key: false,
        display: function(row) {
          console.log(typeof row.datetime)
          if (typeof row.datetime !== "undefined") {
            return (
              row.datetime.substring(0, 10) +
              "<br>" +
              row.datetime.substring(11, 16)
            );
          } else {
            return "";
          }
        },
      },
      {
        label: "Name",
        id: "name",
        sortable: true,
        key: false,
        display: null,
      },
      // {
      //   label: "Location",
      //   id: "location",
      //   sortable: false,
      //   key: false,
      //   display: null,
      // },
    ];

    const rowsPerPage = [
      {
        key: "1",
        text: "10",
        value: 10,
      },
      {
        key: "2",
        text: "50",
        value: 50,
      },
      {
        key: "3",
        text: "100",
        value: 100,
      },
      {
        key: "4",
        text: "1000",
        value: 1000,
      },
    ];
    return {
      items,
      rowsPerPage,
      tableColumns,
    };
  },
});
