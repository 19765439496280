
/* MDCProgressBar
 * Usage: <mdc-indicator>other_html</mdc-indicator>
 * You can send open and close events to the component to open or close it.
 */
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { MDCLinearProgress } from "@material/linear-progress";

export default defineComponent({
  name: "mdcDrawer",
  props: {},
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters.loading);
    // const indicatorKey = ref(false);
    store.watch(
      (state: any) => state.loading,
      () => {
        if (store.getters.loading > 0) {
          linearProgress.open();
          linearProgress.determinate = false;
        } else {
          linearProgress.close();
        }
      },
      { deep: true }
    );

    const refMdcLinearProgress = ref(null);
    let linearProgress: MDCLinearProgress;

    onMounted(() => {
      linearProgress = new MDCLinearProgress(refMdcLinearProgress.value);
      // linearProgress.close();
    });

    return { refMdcLinearProgress, loading };
  },
});
