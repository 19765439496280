import { VuexModule, Module, MutationAction, Mutation, Action, getModule } from "vuex-module-decorators"
import store, { storagekey } from "@/stores/store";
//import _keyBy from 'lodash/keyBy'
import { IAuthenticationRequest, IAuthenticationResponse, IMemberProfile } from "@/models";

import api from "@/services/Api";

@Module({
    name: "auth",
    store: store,
    namespaced: true,
    dynamic: true
})
class AuthStore extends VuexModule {

    private authentication: IAuthenticationResponse | null = null;
    private errorMessage = "";
    private profile: IMemberProfile | null = null;

    @Mutation
    private setAuthenticatedUser(authResult: IAuthenticationResponse) {
        this.authentication = authResult;
    }

    @Mutation
    private unSetAuthenticatedUser() {
        this.authentication = null;
    }

    @Mutation
    private clearErrorMessage() {
        this.errorMessage = "";
    }

    @Mutation
    private setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    @Mutation
    private setProfile(profile: IMemberProfile) {
        this.profile = profile;
    }

    @Mutation
    private clearProfile() {
        this.profile = null;
    }

    get isAuthenticated(): boolean {
        return this.authentication !== null;
    }

    get error(): string {
        return this.errorMessage;
    }

    get roles(): string[] {
        return this.profile === null ? [] : this.profile.roles;
    }

    get username(): string {
        return this.profile === null ? "" : this.profile.userId;
    }

    get fullname(): string {
        return this.profile === null ? "" : this.profile.fullname;
    }

    @Action
    async authenticateUser(request: IAuthenticationRequest): Promise<boolean> {

        return new Promise<boolean>((resolve) => {
            api.login(request).then(async (response: IAuthenticationResponse) => {
                api.setBearer(response.bearerToken);
                this.context.commit("setAuthenticatedUser", response);
                this.clearErrorMessage();
                this.context.dispatch("startRefreshTimer", response.timeToLive);
                this.context.dispatch("fetchProfile");
                resolve(true);
            }).catch((err) => {
                api.clearBearer();
                this.context.commit("unSetAuthenticatedUser");
                this.setErrorMessage("Ogiltig inloggning.");
                this.context.dispatch("disposeProfile");
                resolve(false);
            });
        });

    }

    @Action
    async refresh() {

        api.refresh().then(async (response: IAuthenticationResponse) => {
            api.setBearer(response.bearerToken);
            this.context.commit("setAuthenticatedUser", response);
            this.clearErrorMessage();
            this.context.dispatch("startRefreshTimer", response.timeToLive);
            this.context.dispatch("fetchProfile");
        }).catch((err) => {
            api.clearBearer();
            this.context.commit("unSetAuthenticatedUser");
            this.context.dispatch("disposeProfile");
        });
    }

    @Action
    async logout() {
        api.logout().then(async (success: boolean) => {
            if (success) {
                api.clearBearer();
                this.context.commit("unSetAuthenticatedUser");
                this.clearErrorMessage();
                this.context.dispatch("disposeProfile");
            }
        });
    }

    @Action
    async startRefreshTimer(ttl: number) {
        const timeOut = (ttl - 60);
        console.log(`Refreshing token in ${timeOut} seconds.`);
        setTimeout(() => {
            this.context.dispatch("refresh");
        }, timeOut * 1000);
    }

    @Action
    async fetchProfile() {
        api.getUserProfile().then((profile: IMemberProfile) => {
            this.context.commit("setProfile", profile);
        });
    }

    @Action
    async disposeProfile() {
        this.context.commit("clearProfile");
    }

    @Action
    async authenticateGoogleUser(idtoken: any): Promise<boolean> {

        return new Promise<boolean>((resolve) => {
            api.authGoogle(idtoken).then(async (response: IAuthenticationResponse) => {
                api.setBearer(response.bearerToken);
                console.log(response);
                this.context.commit("setAuthenticatedUser", response);
                this.clearErrorMessage();
                this.context.dispatch("startRefreshTimer", response.timeToLive);
                //this.context.dispatch("fetchProfile");
                resolve(true);
            }).catch((err) => {
                api.clearBearer();
                this.context.commit("unSetAuthenticatedUser");
                this.setErrorMessage("Ogiltig inloggning.");
                this.context.dispatch("disposeProfile");
                resolve(false);
            });
        });

    }

}

export default getModule(AuthStore);
