
import {
  defineComponent,
  nextTick,
  PropType,
  // reactive,
  ref,
  // toRefs,
  onMounted,
} from "vue";
import { InfoItem } from "@/models";
// import { useStore } from "vuex";

import { MDCTextField } from "@material/textfield";
import { MDCDialog } from "@material/dialog";

import resizeImage from "@/functions/resizeimage";

interface detail {
  action: string;
}

export default defineComponent({
  name: "infoItem",
  props: {
    item: Object as PropType<InfoItem>,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // const store = useStore();

    const item = props.item;

    let showEditor = ref<boolean>(false);
    const linkText = ref<string>("");
    const inputDialog = ref(null);
    const inputText = ref(null);
    const infoContent = ref(null);
    const editPopout = ref<boolean>(false);

    let inputDialogObj: MDCDialog;
    let textField: MDCTextField;
    let oldRange: Range;

    let oldContent: string = "";

    onMounted(() => {
      inputDialogObj = new MDCDialog(inputDialog.value);
      textField = new MDCTextField(inputText.value);
      inputDialogObj.listen(
        "MDCDialog:closed",
        (action: CustomEvent & { detail: detail }) => {
          if (
            action.detail.action === "close" ||
            action.detail.action === "cancel"
          ) {
            return;
          } else {
            restoreSelection();
            document.execCommand("createlink", false, textField.value);
          }
        }
      );
      if (props.edit === true) {
        makeContentEditableProps(infoContent.value);
      }
    });

    async function handleImage(event: Event & { target: HTMLInputElement }) {
      const selectedImage = event.target.files[0];
      if (!selectedImage.type.match(/image.*/)) {
        return;
      }
      const config = {
        file: selectedImage,
        maxSize: 500,
      };
      const resizedImage: string = await resizeImage(config);
      document.execCommand("insertImage", false, resizedImage);
      event.target.value = ""; //Clear uploaded image
    }

    async function makeContentEditable(
      event: MouseEvent & { target: Element }
    ) {
      let target = event.target.parentNode.firstChild;
      if (target !== null) {
        showEditor.value = true;
        await nextTick(); //Vänta på att sidan rendreras om så att vi kan fokusera på rutan
        let selection = window.getSelection();
        let range = document.createRange();
        range.setStart(infoContent.value, 0);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        oldContent = infoContent.value.innerHTML;
      }
    }

    async function makeContentEditableProps(target) {
      if (target !== null) {
        showEditor.value = true;
        await nextTick(); //Vänta på att sidan rendreras om så att vi kan fokusera på rutan
        let selection = window.getSelection();
        let range = document.createRange();
        range.setStart(infoContent.value, 0);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        oldContent = infoContent.value.innerHTML;
      }
    }

    function saveNewsEdit(param, event) {
      showEditor.value = false;
      editPopout.value = false;

      context.emit("save", {
        id: item.id,
        title: item.title,
        info_type: item.info_type,
        info_datetime: new Date().toISOString(),
        info_content: infoContent.value.innerHTML,
        info_edit_allowed: true,
      });

      // store.dispatch("updateNews", {
      //   id: item.id,
      //   id: item.id,
      //   info_type: "blog",
      //   info_datetime: new Date().toISOString(),
      //   info_content: infoContent.value.innerHTML,
      //   info_edit_allowed: true,
      // });
    }

    function cancelNewsEdit(param, event) {
      showEditor.value = false;
      editPopout.value = false;
      infoContent.value.innerHTML = oldContent;
      context.emit("save");
    }

    function setCommand(param, options?) {
      if (param == "createlink") {
        saveSelection();
        linkText.value = "Länk (URL)";
        inputDialogObj.open();
        return;
      }
      document.execCommand(param, false, options);
    }

    function saveSelection() {
      oldRange = window.getSelection().getRangeAt(0);
    }

    function restoreSelection() {
      let newselection = window.getSelection();
      newselection.removeAllRanges();
      newselection.addRange(oldRange);
    }

    const styles = {
      styles: {
        type: String,
        required: true,
      },
    };

    function pasteOverride(event) {
      // console.log(event.clipboardData.types);
      if (event.clipboardData.types.includes("text/html")) {
        let paste = event.clipboardData.getData("text/html");
        if (paste.indexOf("urn:schemas-microsoft-com:office:excel") >= 0) {
          //Add support for paste from Excel
          let css_class: [string] = paste.match(/[^{]*\{([^}]*)*}/g); //split up css classes into arrays (also other stuff is included that have the same pattern)
          css_class.forEach((element) => {
            if (element.indexOf(".xl") >= 0) {
              let tmp = element.trim().replace(/\r\n/g, ""); //trim and remove all crlf from string
              let classname = tmp.substr(1, tmp.indexOf("\t") - 1); //get class name
              let cssdata = tmp.match(/[^{{]+(?=\})/g); //get CSS data inside brackets
              paste = paste.replace(
                "class=" + classname + " style='", //remove class data and style
                "style='" + cssdata //insert only style + cssdata from class
              );
              paste = paste.replace(
                "class=" + classname, //remove class data if cell has no style
                "style='" + cssdata + "'" //insert only style + cssdata from class
              );
            }
          });
          document.execCommand("insertHTML", false, paste);
          event.preventDefault();
        }
      } else if (event.clipboardData.types.includes("text/plain")) {
        let paste = event.clipboardData.getData("text/plain");
        if (paste.search(/^https?\:\/\//i) == 0) {
          //look for a string beginning with http:// or https:// and convert it to a hyperlink instead of plaintext
          document.execCommand(
            "insertHTML",
            false,
            "<a href='" + paste + "'>" + paste + "</a>"
          );
          event.preventDefault();
        }
        const emailregx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
        if (emailregx.test(paste)) {
          //find email adress in text and add hyperlink mailto:
          let email = paste.match(emailregx);
          let newstring = paste.replace(
            emailregx,
            "<a href='mailto:" + email + "'>" + email + "</a>"
          );
          document.execCommand("insertHTML", false, newstring);
          event.preventDefault();
        }
      }
    }
    return {
      item,
      makeContentEditable,
      showEditor,
      saveNewsEdit,
      cancelNewsEdit,
      setCommand,
      inputText,
      linkText,
      inputDialog,
      pasteOverride,
      handleImage,
      editPopout,
      infoContent,
    };
  },
});
