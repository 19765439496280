import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

export interface HttpClient {
    get<TOut>(url: string): Promise<TOut>;
    post<TIn, TOut>(url: string, data: TIn): Promise<TOut>;
    put<TIn, TOut>(url: string, data: TIn): Promise<TOut>;
    delete(url: string, params?: any): Promise<void>;
}

export default abstract class HttpClientImpl implements HttpClient {
    protected readonly instance: AxiosInstance;

    public constructor(baseUrl: string) {
        this.instance = axios.create({
            baseURL: baseUrl,
            headers: { "Content-Type": "application/json" }
        });
    }

    get = async <TOut>(url: string, params: any = null): Promise<TOut> => {
        return await this.instance.get(url, { params: params, withCredentials: true });
    }

    post = async <TIn, TOut>(url: string, data: TIn): Promise<TOut> => {
        return await this.instance.post(url, data, { withCredentials: true });
    }

    put = async <TIn, TOut>(url: string, data: TIn): Promise<TOut> => {
        return await this.instance.put(url, data, { withCredentials: true });
    }

    patch = async <TIn, TOut>(url: string, data: TIn): Promise<TOut> => {
        return await this.instance.patch(url, data, { withCredentials: true });
    }

    delete = async (url: string, params?: any): Promise<void> => {
        return await this.instance.delete(url, { params: params, withCredentials: true });
    }
}