<template>
  <div class="mdc-form-field">
    <div v-wave class="mdc-checkbox mdc-checkbox--touch mdc-ripple-border-fix">
      <input
        type="checkbox"
        class="mdc-checkbox__native-control"
        :id="id"
        :checked="modelValue"
        @input="updateValue"
        :disabled="disabled"
      />
      <div class="mdc-checkbox__background">
        <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
          <path
            class="mdc-checkbox__checkmark-path"
            fill="none"
            d="M1.73,12.91 8.1,19.28 22.79,4.59"
          />
        </svg>
        <div class="mdc-checkbox__mixedmark"></div>
      </div>
    </div>
    <label :for="id"><slot></slot></label>
  </div>
</template>

<script lang="ts">
/* MDCCheckbox
 * Usage: <mdc-checkbox id="uniqueId">TextOnCheckbox</mdc-checkbox>
 * Id should be supplied to keep label and input connection
 * If you supply disabled attribute <mdc-checkbox disabled>TextOnCheckbox</mdc-checkbox> the input will be disabled
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "mdcCheckbox",
  props: {
    id: {
      type: String,
      default: "checkbox-1",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, context) {
    function updateValue(event: MouseEvent & { target: HTMLInputElement }) {
      context.emit("update:modelValue", event.target.checked);
    }

    return { updateValue };
  },
});
</script>

<style lang="sass" scoped></style>
