import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";

// Layout components
//import Default from "@/views/layouts/Default.vue";
//import Empty from "@/views/layouts/Empty.vue";

// Error components
import UnauthorizedView from "@/views/Unauthorized.vue";

// Public components
import Landing from "@/views/Landing.vue";
import Nyheter from "@/views/Nyheter.vue";
import InfoPage from "@/views/InfoPage.vue";
import Competitions from "@/views/Competitions.vue";
import mdcTest from "@/views/mdcTest.vue";

// Account components
import Login from "@/views/account/login.vue";
//import ProfileView from "@/views/account/Profile.vue";

// Restricted components
//import AdminView from "@/views/admin/AdminView.vue";
//import MemberView from "@/views/member/MemberView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: Nyheter,
  },
  {
    path: "/",
    alias: ["/skjutprogram"],
    name: "Competitions",
    component: Competitions,
  },
  {
    path: "/",
    alias: "/info/:shortName",
    name: "info",
    component: InfoPage,
  },
  {
    path: "/",
    alias: "/mdctest",
    name: "mdctest",
    component: mdcTest,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  //{
  //    path: "/admin",
  //    name: "admin",
  //    component: Default,
  //    meta: { secure: true },
  //    children: [
  //        {
  //            path: "advertisement",
  //            component: Empty,
  //            meta: { secure: true, roles: ["admin"] },
  //            children: [
  //                //{
  //                //    path: "advertisers",
  //                //    component: AdvertisersView,
  //                //    meta: { secure: true, roles: ["admin"] }
  //                //},
  //                //{
  //                //    path: "booking",
  //                //    component: AdBookingsView,
  //                //    meta: { secure: true, roles: ["admin"] }
  //                //}
  //            ]
  //        },
  //        //{
  //        //    path: "editorialcontent",
  //        //    component: Empty,
  //        //    //meta: { secure: true, roles: ["admin"] },
  //        //    children: [
  //        //        {
  //        //            path: "contentview",
  //        //            component: ContentView,
  //        //            //meta: { secure: true, roles: ["admin"] }
  //        //        }
  //        //    ]
  //        //},
  //        //{
  //        //    path: "editions",
  //        //    component: EditionsEditorView,
  //        //    meta: { secure: true, roles: ["admin"] }
  //        //}
  //    ]
  //},
  //{
  //    path: "/pages",
  //    name: "pages",
  //    component: Default,
  //    children: [
  //        {
  //            path: "login",
  //            component: LoginView
  //        },
  //        //{
  //        //    path: "advertisement",
  //        //    alias: ["/ads", "/annonsera"],
  //        //    component: Advertisement
  //        //},
  //        //{
  //        //    path: "advertisement-request",
  //        //    component: AdvertisementRequest
  //        //},
  //        //{
  //        //    path: "productionplan",
  //        //    component: ProductionPlan
  //        //},
  //        //{
  //        //    path: "formatcosts",
  //        //    component: AdFormatsAndCosts
  //        //},
  //        //{
  //        //    path: "tips",
  //        //    component: Tips
  //        //},
  //        //{
  //        //    path: "write",
  //        //    component: Write
  //        //},
  //        {
  //            path: "profile",
  //            component: ProfileView,
  //            meta: { secure: true }
  //        },
  //        {
  //            path: "unauthorized",
  //            component: UnauthorizedView
  //        }
  //    ]
  //}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "mdc-list-item--selected",
});

export default router;
