
//TODO: Håll koll på checkbox finns i MDC
/* MDCDataTable
 * Usage: <mdc-data-table>favorite</mdc-data-table> and <mdc-data-table icon="favorite"></mdc-data-table>
 * Iconname can be supplied either by attribute or text inside tags. https://material.io/resources/icons for valid icon names.
 * When iconOn is supplied toggle mode is enabled on the button
 * When passing defaultOn the button is initialized in state "On"
 * <mdc-data-table icon="favorite_border" iconOn="favorite" defaultOn></mdc-data-table>
 */
import {
  onMounted,
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
} from "vue";
import { MDCDataTable, SortActionEventDetail } from "@material/data-table";

interface MDCRowsPerPage {
  key: string;
  value: number;
}

interface MDCTable {
  currentSort: string;
  currentSortDir: string;
  pageSize: number;
  currentPage: number;
}

interface MDCColumns {
  //isKey: Boolean; // If field is primary key. set to true
  label: string; // Display field lable
  id: string; // Your data key
  type: string; // Numeric
  //width: String; // Column's width
  sortable: boolean; // If field can sorting. set to true
  display: string; // You can customize display data in there. (html tag or Etc.)
}

export default defineComponent({
  name: "mdcDataTable",
  props: {
    rowsPerPage: {
      type: Object as PropType<Array<MDCRowsPerPage>>,
      default: null,
    },
    rows: {
      type: Array as PropType<Array<any>>,
      default: null,
    },
    columns: {
      type: Object as PropType<Array<MDCColumns>>,
      default: null,
    },
    showprogress: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, _context) {
    const refDataTable = ref(null);
    const selectedRowsPerPage = ref(props.rowsPerPage[0].value);
    let sortValue = ref("");
    let columnId = ref("");
    const currentPage = ref(1);
    const currentRows = computed(() => {
      if (sortValue.value === "ascending") {
        return props.rows.sort((a: any, b: any) =>
          a[columnId.value] > b[columnId.value] ? 1 : b[columnId.value] > a[columnId.value] ? -1 : 0
        );
      } else if (sortValue.value === "descending") {
        return props.rows.sort((a: any, b: any) =>
          a[columnId.value] < b[columnId.value] ? 1 : b[columnId.value] < a[columnId.value] ? -1 : 0
        );
      } else {
        return props.rows
      }
    });
    const displayedRows = computed(() => {
      return currentRows.value.slice(
        currentStartRow.value - 1,
        currentEndRow.value
      );
    });

    watch(
      () => selectedRowsPerPage.value,
      (newvalue, lastvalue) => {
        //Räkna ut ny startpage när antal per sida ändras ligger kvar till närmaste del.
        if (newvalue > lastvalue) {
          let tmppart =
            currentPage.value / (currentRows.value.length / lastvalue);
          let newmaxpages = currentRows.value.length / newvalue;
          currentPage.value = Math.ceil(tmppart * newmaxpages);
        } else {
          let tmppart =
            currentPage.value / (currentRows.value.length / lastvalue) -
            1 / (currentRows.value.length / lastvalue);
          let newmaxpages = currentRows.value.length / newvalue;
          currentPage.value = Math.ceil(tmppart * newmaxpages) + 1;
        }
      }
    );

    const prevDisable = computed(() => {
      if (currentPage.value > 1) {
        return false;
      } else {
        return true;
      }
    });
    const nextDisable = computed(() => {
      if (
        currentRows.value.length -
          currentPage.value * selectedRowsPerPage.value <
        1
      ) {
        return true;
      } else {
        return false;
      }
    });

    const currentStartRow = computed(() => {
      return Math.max(
        Math.min(
          selectedRowsPerPage.value * currentPage.value -
            selectedRowsPerPage.value +
            1,
          Math.max(currentRows.value.length - selectedRowsPerPage.value + 1, 1)
        ),
        1
      );
    });

    const currentEndRow = computed(() => {
      return Math.min(
        selectedRowsPerPage.value * currentPage.value,
        currentRows.value.length
      );
    });

    function prevPage() {
      currentPage.value--;
    }
    function nextPage() {
      currentPage.value++;
    }
    function firstPage() {
      currentPage.value = 1;
    }
    function lastPage() {
      currentPage.value = Math.floor(
        props.rows.length / selectedRowsPerPage.value
      );
    }

    onMounted(() => {
      const dataTable = new MDCDataTable(refDataTable.value);
      //TODO:Implement progressbars
      // dataTable.showProgress();
      // dataTable.hideProgress();

      dataTable.listen(
        "MDCDataTable:sorted",
        (SortEvent: Event & { detail: SortActionEventDetail }) => {
          currentPage.value = 1;
          columnId.value = SortEvent.detail.columnId;
          sortValue.value = SortEvent.detail.sortValue;
          // if (SortEvent.detail.sortValue === "ascending") {
          //   itemRows.value = itemRows.value.sort((a: any, b: any) =>
          //     a[columnId] > b[columnId] ? 1 : b[columnId] > a[columnId] ? -1 : 0
          //   );
          // } else if (SortEvent.detail.sortValue === "descending") {
          //   itemRows.value = itemRows.value.sort((a: any, b: any) =>
          //     a[columnId] < b[columnId] ? 1 : b[columnId] < a[columnId] ? -1 : 0
          //   );
          // }
        }
      );
    });

    return {
      refDataTable,
      selectedRowsPerPage,
      currentStartRow,
      currentEndRow,
      prevPage,
      nextPage,
      prevDisable,
      nextDisable,
      firstPage,
      lastPage,
      currentRows,
      displayedRows,
      currentPage,
    };
  },
});
