<template>
  <div ref="refDataTable" class="mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table" aria-label="Dessert calories">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th
              v-for="column in columns"
              v-bind:key="column.id"
              class="mdc-data-table__header-cell"
              :class="{
                'mdc-data-table__header-cell--with-sort': column.sortable,
                'mdc-data-table__header-cell--numeric':
                  column.type === 'numeric',
              }"
              role="columnheader"
              scope="col"
              aria-sort="none"
              :data-column-id="column.id"
            >
              <div
                v-if="column.sortable"
                class="mdc-data-table__header-cell-wrapper"
              >
                <div
                  class="mdc-data-table__header-cell-label"
                  v-if="column.type !== 'numeric'"
                >
                  {{ column.label }}
                </div>
                <div v-wave class="ripple">
                  <button
                    class="ripplefix mdc-icon-button material-icons mdc-data-table__sort-icon-button"
                    :aria-label="'Sort by ' + column.label"
                    aria-describedby="dessert-status-label"
                  >
                    arrow_upward
                  </button>
                </div>

                <div
                  class="mdc-data-table__header-cell-label"
                  v-if="column.type === 'numeric'"
                >
                  {{ column.label }}
                </div>
                <div
                  class="mdc-data-table__sort-status-label"
                  aria-hidden="true"
                  :id="column.label + ' dessert-status-label'"
                ></div>
              </div>
              <div v-else>
                {{ column.label }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content" v-if="rows.length > 0">
          <tr
            class="mdc-data-table__row"
            v-for="row in displayedRows"
            :key="row.id"
          >
            <!-- <td v-if="hasCheckbox"> //TODO: Fixa checkbox -->
            <td
              v-for="(col, j) in columns"
              :key="j"
              class="mdc-data-table__cell"
              :class="{
                'mdc-data-table__cell--numeric': col.type === 'numeric',
              }"
            >
              <div v-if="col.display" v-html="col.display(row)"></div>
              <span v-else>{{ row[col.id] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mdc-data-table__progress-indicator">
      <div class="mdc-data-table__scrim"></div>
      <div
        class="mdc-linear-progress mdc-linear-progress--indeterminate mdc-data-table__linear-progress"
        role="progressbar"
        aria-label="Data is being loaded..."
      >
        <div class="mdc-linear-progress__buffer">
          <div class="mdc-linear-progress__buffer-bar"></div>
          <div class="mdc-linear-progress__buffer-dots"></div>
        </div>
        <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
          <span class="mdc-linear-progress__bar-inner"></span>
        </div>
        <div
          class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar"
        >
          <span class="mdc-linear-progress__bar-inner"></span>
        </div>
      </div>
    </div>
    <div class="mdc-data-table__pagination">
      <div class="mdc-data-table__pagination-trailing">
        <div class="rowsperpagefix mdc-data-table__pagination-rows-per-page">
          <div class="mdc-data-table__pagination-rows-per-page-label">
            Antal per sida
          </div>

          <div
            class="mdc-select mdc-select--outlined mdc-select--no-label mdc-data-table__pagination-rows-per-page-select"
          >
            <mdc-select
              tabindex="0"
              :items="rowsPerPage"
              v-model="selectedRowsPerPage"
            ></mdc-select>
          </div>
        </div>

        <div class="mdc-data-table__pagination-navigation">
          <div class="totalsfix mdc-data-table__pagination-total">
            {{ currentStartRow }}‑{{ currentEndRow }} of
            {{ rows.length }}
          </div>
          <mdc-icon-button
            class="mdc-data-table__pagination-button"
            @click="firstPage"
            data-first-page="true"
            :disabled="prevDisable"
          >
            <div class="mdc-button__icon">first_page</div>
          </mdc-icon-button>
          <mdc-icon-button
            class="mdc-data-table__pagination-button"
            @click="prevPage"
            data-prev-page="true"
            :disabled="prevDisable"
          >
            <div class="mdc-button__icon">chevron_left</div>
          </mdc-icon-button>
          <mdc-icon-button
            class="mdc-data-table__pagination-button"
            @click="nextPage"
            data-next-page="true"
            :disabled="nextDisable"
            >chevron_right</mdc-icon-button
          >
          <mdc-icon-button
            class="mdc-data-table__pagination-button"
            @click="lastPage"
            data-last-page="true"
            :disabled="nextDisable"
            >last_page</mdc-icon-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
//TODO: Håll koll på checkbox finns i MDC
/* MDCDataTable
 * Usage: <mdc-data-table>favorite</mdc-data-table> and <mdc-data-table icon="favorite"></mdc-data-table>
 * Iconname can be supplied either by attribute or text inside tags. https://material.io/resources/icons for valid icon names.
 * When iconOn is supplied toggle mode is enabled on the button
 * When passing defaultOn the button is initialized in state "On"
 * <mdc-data-table icon="favorite_border" iconOn="favorite" defaultOn></mdc-data-table>
 */
import {
  onMounted,
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
} from "vue";
import { MDCDataTable, SortActionEventDetail } from "@material/data-table";

interface MDCRowsPerPage {
  key: string;
  value: number;
}

interface MDCTable {
  currentSort: string;
  currentSortDir: string;
  pageSize: number;
  currentPage: number;
}

interface MDCColumns {
  //isKey: Boolean; // If field is primary key. set to true
  label: string; // Display field lable
  id: string; // Your data key
  type: string; // Numeric
  //width: String; // Column's width
  sortable: boolean; // If field can sorting. set to true
  display: string; // You can customize display data in there. (html tag or Etc.)
}

export default defineComponent({
  name: "mdcDataTable",
  props: {
    rowsPerPage: {
      type: Object as PropType<Array<MDCRowsPerPage>>,
      default: null,
    },
    rows: {
      type: Array as PropType<Array<any>>,
      default: null,
    },
    columns: {
      type: Object as PropType<Array<MDCColumns>>,
      default: null,
    },
    showprogress: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, _context) {
    const refDataTable = ref(null);
    const selectedRowsPerPage = ref(props.rowsPerPage[0].value);
    let sortValue = ref("");
    let columnId = ref("");
    const currentPage = ref(1);
    const currentRows = computed(() => {
      if (sortValue.value === "ascending") {
        return props.rows.sort((a: any, b: any) =>
          a[columnId.value] > b[columnId.value] ? 1 : b[columnId.value] > a[columnId.value] ? -1 : 0
        );
      } else if (sortValue.value === "descending") {
        return props.rows.sort((a: any, b: any) =>
          a[columnId.value] < b[columnId.value] ? 1 : b[columnId.value] < a[columnId.value] ? -1 : 0
        );
      } else {
        return props.rows
      }
    });
    const displayedRows = computed(() => {
      return currentRows.value.slice(
        currentStartRow.value - 1,
        currentEndRow.value
      );
    });

    watch(
      () => selectedRowsPerPage.value,
      (newvalue, lastvalue) => {
        //Räkna ut ny startpage när antal per sida ändras ligger kvar till närmaste del.
        if (newvalue > lastvalue) {
          let tmppart =
            currentPage.value / (currentRows.value.length / lastvalue);
          let newmaxpages = currentRows.value.length / newvalue;
          currentPage.value = Math.ceil(tmppart * newmaxpages);
        } else {
          let tmppart =
            currentPage.value / (currentRows.value.length / lastvalue) -
            1 / (currentRows.value.length / lastvalue);
          let newmaxpages = currentRows.value.length / newvalue;
          currentPage.value = Math.ceil(tmppart * newmaxpages) + 1;
        }
      }
    );

    const prevDisable = computed(() => {
      if (currentPage.value > 1) {
        return false;
      } else {
        return true;
      }
    });
    const nextDisable = computed(() => {
      if (
        currentRows.value.length -
          currentPage.value * selectedRowsPerPage.value <
        1
      ) {
        return true;
      } else {
        return false;
      }
    });

    const currentStartRow = computed(() => {
      return Math.max(
        Math.min(
          selectedRowsPerPage.value * currentPage.value -
            selectedRowsPerPage.value +
            1,
          Math.max(currentRows.value.length - selectedRowsPerPage.value + 1, 1)
        ),
        1
      );
    });

    const currentEndRow = computed(() => {
      return Math.min(
        selectedRowsPerPage.value * currentPage.value,
        currentRows.value.length
      );
    });

    function prevPage() {
      currentPage.value--;
    }
    function nextPage() {
      currentPage.value++;
    }
    function firstPage() {
      currentPage.value = 1;
    }
    function lastPage() {
      currentPage.value = Math.floor(
        props.rows.length / selectedRowsPerPage.value
      );
    }

    onMounted(() => {
      const dataTable = new MDCDataTable(refDataTable.value);
      //TODO:Implement progressbars
      // dataTable.showProgress();
      // dataTable.hideProgress();

      dataTable.listen(
        "MDCDataTable:sorted",
        (SortEvent: Event & { detail: SortActionEventDetail }) => {
          currentPage.value = 1;
          columnId.value = SortEvent.detail.columnId;
          sortValue.value = SortEvent.detail.sortValue;
          // if (SortEvent.detail.sortValue === "ascending") {
          //   itemRows.value = itemRows.value.sort((a: any, b: any) =>
          //     a[columnId] > b[columnId] ? 1 : b[columnId] > a[columnId] ? -1 : 0
          //   );
          // } else if (SortEvent.detail.sortValue === "descending") {
          //   itemRows.value = itemRows.value.sort((a: any, b: any) =>
          //     a[columnId] < b[columnId] ? 1 : b[columnId] < a[columnId] ? -1 : 0
          //   );
          // }
        }
      );
    });

    return {
      refDataTable,
      selectedRowsPerPage,
      currentStartRow,
      currentEndRow,
      prevPage,
      nextPage,
      prevDisable,
      nextDisable,
      firstPage,
      lastPage,
      currentRows,
      displayedRows,
      currentPage,
    };
  },
});
</script>

<style scoped>
.ripplefix {
  margin: 0px;
}

.ripple {
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.rowsperpagefix {
  max-width: 185px;
}

.totalsfix {
  max-width: 92px;
}
</style>
