
/* MDCSelect
 * Usage: <mdc-select v-model="returnedValue" items="data">title</mdc-select>
 * If v-model has value that will be the selected value in the select
 * items contains the data for the list in the select according to MDCSelectItems
 */
import { onMounted, defineComponent, ref, PropType } from "vue";
import { MDCSelect } from "@material/select";
interface MDCSelectItems {
  key: string;
  text: string;
  value: string | number;
}
export default defineComponent({
  name: "mdcSelect",
  props: {
    items: {
      type: Object as PropType<Array<MDCSelectItems>>,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  setup(_props, context) {
    const refSelect = ref(null);
    const slots = ref(
      context.slots.default &&
        context.slots.default().findIndex((o) => o.type !== Comment) !== -1
    );

    onMounted(() => {
      const select = new MDCSelect(refSelect.value);
      select.listen("MDCSelect:change", () => {
        context.emit("update:modelValue", select.value);
      });
    });

    return { refSelect, slots };
  },
});
