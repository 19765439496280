
/* MDCSwitch
 * Usage: <mdc-switch id="uniqueId" v-bind="value">TextOnSwitch</mdc-switch>
 * Id should be supplied to keep label and input connection
 * If you set the choosenvalue v-bind value that is the default state of the switch
 * If you supply disabled attribute <mdc-switch disabled>TextOnSwitch</mdc-switch> the input will be disabled
 */
import { defineComponent, onMounted, ref } from "vue";
import { MDCSwitch } from "@material/switch";

export default defineComponent({
  name: "mdcSwitch",
  props: {
    id: {
      type: String,
      default: "switch-1",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const refMdcSwitch = ref(null);
    let switchControl: MDCSwitch;

    function updateValue(event: MouseEvent & { target: MDCSwitch }) {
      context.emit("update:modelValue", event.target.checked);
    }

    onMounted(() => {
      switchControl = new MDCSwitch(refMdcSwitch.value);
      switchControl.disabled = props.disabled;
      switchControl.checked = props.modelValue;
    });

    return { updateValue, refMdcSwitch };
  },
});
