import { formatDistanceToNow } from "date-fns";
import { createStore } from "vuex";
import { VuexPersistence } from "vuex-persist";
import appApi from "@/services/Api";

//VuexModule, vue-module-decorators
//store, {storagekey}

export const storagekey = "lovstaapp";

const vuexLocalStore = new VuexPersistence({
  storage: localStorage,
  key: storagekey,
  reducer: (state: any) => ({
    profile: state.profile,
  }),
});

const store = createStore({
  state: {
    news: [{}],
    infopages: [
      // {
      //   info_id: 0,
      //   title: "Loading",
      //   info_datetime: "2021-02-16T16:04:45.923Z",
      //   info_content:
      //     'Loading...',
      //   info_edit_allowed: true,
      // },
    ],
    snacks: [],
    menus: [{ key: 0, text: "Loading...", group: "All", icon: "", href: "/" }],
    loading: 0,
    competitions: [{
      // id: 0,
      // type: "Sport- och grovpistol",
      // name: "Sportpistol 1",
      // datetime: "2021-04-06 18:00",
      // location: "Lövsta",
      // host: "Lövsta Skf",
      // rangeofficer: {name: "Test Testson", memberid: 12},
      // clubweapons: false,
      // membersonly: true,
      // openforregistration: true,
      // description: "Välkommen till årets första Sportpistol.<br>Tänk på att hålla avstånd!",
      // edit_allowed: true,
      // attachments: {url: "http://www.lovstaskytte.se/files/sportpistol1.pdf", displayname: "Inbjudan"},
      // link: "http://salemspk.se/"
    }],
    count: 4,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    createNews(state, payload) {
      state.news.push(payload);
    },
    setLoadedNews(state, payload) {
      state.news = payload;
    },
    setLoading(state, payload) {
      if (payload === true) {
        state.loading++;
      } else {
        state.loading--;
      }
    },
    updateNews(state, payload) {
      const index = state.news.findIndex(
        (item) => item.info_id === payload.info_id
      );
    },
    setLoadedMenus(state, payload) {
      state.menus = payload;
    },
    setLoadedCompetitions(state, payload) {
      state.competitions = payload;
    },
    createSnack(state, payload) {
      state.snacks.push(payload);
    },
    removeSnack(state) {
      state.snacks.shift();
    },
    addInfoPage(state, payload) {
      const index = state.infopages.findIndex(
        (item) => item.shortName === payload[0].shortName
      );
      if (payload.length > 0) {
        if (index > -1) {
          state.infopages[index] = payload[0];
        } else {
          state.infopages.push(payload[0]);
        }
      }
    },
    createInfoPage(state, payload) {
      state.infopages.push(payload);
    },
    updateInfoPage(state, payload) {
      const index = state.infopages.findIndex(
        (item) => item.info_id === payload.info_id
      );
    },
  },
  actions: {
    async loadInfoPage({ commit }, payload: string) {
      commit("setLoading", true);
      appApi
        .getInfoItem(payload.toUpperCase())
        //TODO: Request infopage, store it in store offline, pass shortname to getInfoItem
        .then((items) => {
          commit("addInfoPage", items);
          //TODO: Store it in store, support offline
          commit("setLoading", false);
        })
        .catch((error) => {
          handleError(error);
          commit("setLoading", false);
        });
    },

    async loadMenus({ commit }) {
      commit("setLoading", true);
      appApi
        .getMenuItems()
        .then((items) => {
          commit("setLoadedMenus", items);
          commit("setLoading", false);
        })
        .catch((error) => {
          handleError(error);
          commit("setLoading", false);
        });
    },
    async loadCompetitions({ commit }) {
      commit("setLoading", true);
      appApi
        .getCompetitions()
        .then((items) => {
          commit("setLoadedCompetitions", items);
          commit("setLoading", false);
        })
        .catch((error) => {
          handleError(error);
          commit("setLoading", false);
        });
    },
    async loadNews({ commit }) {
      commit("setLoading", true);
      appApi
        .getInfoItems()
        .then((items) => {
          commit("setLoadedNews", items);
          commit("setLoading", false);
        })
        .catch((error) => {
          handleError(error);
          commit("setLoading", false);
        });
    },
    createNews({ commit }, payload) {
      const news = {
        blog_id: 0,
        blog_datetime: new Date().toISOString(),
        blog_title: payload.title,
        blog_text: payload.text,
      };
      //TODO: Använd backend API
      commit("createNews", news);
    },
    updateNews({ commit }, payload) {
      appApi
        .updateInfoItems(payload)
        .then((item) => {
          commit("updateNews", item);
        })
        .catch((error) => {
          handleError(error);
        });
    },
    createSnack({ commit }, payload) {
      const snack = {
        labelText: payload.labelText,
        actionButtonText: payload.actionButtonText,
        dismissed: false,
      };
      commit("createSnack", snack);
    },
    removeSnack({ commit }) {
      commit("removeSnack");
    },
    createInfoPage({ commit }, payload) {
      const infopage = {
        info_datetime: new Date().toISOString(),
        title: payload.title,
        info_type: "infoPage",
        info_content: "",
        info_edit_allowed: true,
      };
      appApi
        .createInfoItem(infopage)
        .then((item) => {
          commit("createInfoPage", item);
        })
        .catch((error) => {
          handleError(error);
        });
    },
    updateInfoPage({ commit }, payload) {
      appApi
        .updateInfoItem(payload)
        .then((item) => {
          commit("updateInfoPage", item);
        })
        .catch((error) => {
          handleError(error);
        });
    },
  },
  getters: {
    loadedMenus(state) {
      return state.menus;
    },
    loadedNews(state) {
      return state.news.sort((newsA, newsB) => {
        return newsA.blog_datetime > newsB.blog_datetime;
      });
    },
    loading(state) {
      return state.loading;
    },
    snacks(state) {
      return state.snacks;
    },
    loadedInfoPages: (state) => (shortname: string) => {
      return state.infopages.find(
        ({ title }) => title === shortname.toUpperCase()
      );
    },
    loadedCompetitions(state) {
      return state.competitions;
    }
  },
  plugins: [vuexLocalStore.plugin],
});

export function awaitStateInit(): Promise<void> {
  return new Promise<void>((resolve) => {
    while (store.state === null) {
      setTimeout(() => {
        // Just wait
      }, 50);
    }
    resolve();
  });
}
function handleError(error) {
  if (error.message === "Network Error") {
    store.dispatch("createSnack", {
      labelText: "Kan inte hämta data från servern",
      actionButtonText: "Ok",
    });
  }
}

export default store;
