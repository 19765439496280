<template>
  <div class="mdc-switch__sction">
    <section class="mdc-switch__sction-align-start">
      <label :for="id"><slot></slot></label>
    </section>
    <section class="mdc-switch__sction-align-end">
      <div ref="refMdcSwitch" class="mdc-switch">
        <div class="mdc-switch__track"></div>
        <div class="mdc-switch__thumb-underlay">
          <div class="mdc-switch__thumb"></div>
          <input
            type="checkbox"
            :id="id"
            class="mdc-switch__native-control"
            role="switch"
            aria-checked="false"
            @input="updateValue"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
/* MDCSwitch
 * Usage: <mdc-switch id="uniqueId" v-bind="value">TextOnSwitch</mdc-switch>
 * Id should be supplied to keep label and input connection
 * If you set the choosenvalue v-bind value that is the default state of the switch
 * If you supply disabled attribute <mdc-switch disabled>TextOnSwitch</mdc-switch> the input will be disabled
 */
import { defineComponent, onMounted, ref } from "vue";
import { MDCSwitch } from "@material/switch";

export default defineComponent({
  name: "mdcSwitch",
  props: {
    id: {
      type: String,
      default: "switch-1",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const refMdcSwitch = ref(null);
    let switchControl: MDCSwitch;

    function updateValue(event: MouseEvent & { target: MDCSwitch }) {
      context.emit("update:modelValue", event.target.checked);
    }

    onMounted(() => {
      switchControl = new MDCSwitch(refMdcSwitch.value);
      switchControl.disabled = props.disabled;
      switchControl.checked = props.modelValue;
    });

    return { updateValue, refMdcSwitch };
  },
});
</script>

<style scoped>
.mdc-switch__sction {
  display: flex;
  position: relative;
  height: 48px;
}

.mdc-switch__sction-align-start {
  justify-content: flex-start;
  order: -1;
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
}
.mdc-switch__sction-align-end {
  justify-content: flex-end;
  order: 1;
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
}
</style>
