
import { defineComponent, reactive, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import mdcTopAppBar from "./components/UI/mdcTopAppBar.vue";

export default defineComponent({
  components: { mdcTopAppBar },
  name: "App",
  setup() {
    const refSidebar = ref(null);
    const sidebar = reactive({ display: "" });

    const store = useStore();
    store.dispatch("loadMenus");

    const menuitems = computed(() => store.getters.loadedMenus);

    function toggleDrawer() {
      refSidebar.value.open();
    }
    onMounted(() => {
      // store.dispatch("loadNews"); //If not loaded in onmounted the component for the indicator is not working
    });

    return {
      toggleDrawer,
      sidebar,
      refSidebar,
      menuitems,
    };
  },
});
