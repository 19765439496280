import Vue, { App, } from "vue";

declare module "*.vue" {
    interface Vue {
        $facebookAuth: IFacebookAuthConfig
    }
}

declare global {
    interface Window { fbapi: any; fbAsyncInit: any; FB: any; }
}

export interface IFacebookAuthentication {
    init(config: IFacebookAuthConfig): Promise<void>;
    login(): void;
    logout(): void;
}

export interface IFacebookAuthConfig {
    appId: string;
    cookie: boolean;
    xfbml: boolean;
    version: string;
}

interface IFacebookStatusResponse {
    status: string;
    authResponse: IFacebookAuthResponse;
}

interface IFacebookAuthResponse {
    accessToken: string;
    userId: string;
    expiresIn: number;
    signedRequest: string;
    graphDomain: string;
    data_access_expiration_time: number;
}

const facebookAuth = (): IFacebookAuthentication => {

    const installClient = async (config: IFacebookAuthConfig): Promise<void> => {
        return new Promise((resolve) => {

            const apiUrl: string = "https://connect.facebook.net/en_US/sdk.js";
            var script: HTMLScriptElement = document.createElement("script");
            script.src = apiUrl;
            script.crossOrigin = "anonymous";
            script.async = true;
            script.defer = true;

            document.onreadystatechange = script.onload = (ev: Event) => {
                if (document.readyState !== "complete") {
                    setTimeout(() => {
                        resolve();
                    }, 1000);
                }
            };

            window.fbAsyncInit = () => { 
                window.FB.init(config); 
            }

            document.getElementsByTagName("head")[0].appendChild(script);
        })
    }

    const init = async (config: IFacebookAuthConfig): Promise<void> => {
        return new Promise((resolve) => {
            installClient(config).then(() => {
                window.fbAsyncInit();
                resolve();
            })
        });
    }

    const auth = (): IFacebookAuthentication => {

        let authData: IFacebookAuthResponse | null = null;

        const statusChangeCallback = (statusResponse: IFacebookStatusResponse) => {
            console.log(statusResponse);
            authData = statusResponse.authResponse;
        }

        const logout = (): void => {
            window.FB.logout((response) => {
                console.log(response);
            });
        }

        const login = (): Promise<IFacebookAuthResponse> => {

            return new Promise((resolve, reject) => {

                window.FB.getLoginStatus((response: IFacebookStatusResponse) => {

                    if (response.status === "connected")
                    {
                        authData = response.authResponse;
                        resolve(response.authResponse);
                        return;
                    }

                    window.FB.login((response: IFacebookStatusResponse) => {
                        if (response.authResponse) {
                            authData = response.authResponse;
                            resolve(response.authResponse);
                        } else {
                            authData = null;
                            reject();
                        }
                    });
                    
                });

            });
        }



        return {
            init,
            login,
            logout
        };
    }


    return auth();

}

const facebookAuthPlugin = {

    install: (app: App, options: IFacebookAuthConfig) => {

        if (typeof options !== "object" || options === undefined) {
            throw new Error("facebookAuthPlugin: No FacebookAuthConfig provided")
        }

        let facebookDefaultAuthConfig: IFacebookAuthConfig = {
            appId: "",
            cookie: true,       // enable cookies to allow the server to access the session
            xfbml: true,        // parse social plugins on this page
            version: "v2.8"     // use graph api version 2.8
        };

        let facebookAuthConfig: IFacebookAuthConfig | null = null;

        let prompt: string = "select_account";

        facebookAuthConfig = Object.assign(facebookDefaultAuthConfig, options);
        if (options.appId) {
            facebookDefaultAuthConfig.appId = options.appId;
        }

        if (!options.appId) {
            console.warn("appId is required");
        }

        const authenticator: IFacebookAuthentication = facebookAuth();
        authenticator.init(facebookAuthConfig);

        app.provide("facebookAuth", options);

        app.component("facebookAuth", authenticator);
        app.config.globalProperties.$facebookAuth = authenticator;
        app.provide("facebookAuth", authenticator);



    }

};

export default facebookAuthPlugin;