import { validate, ValidationError } from "class-validator";
import { watchEffect, watch } from "vue";

export default abstract class ValidationBase {

    public errors: Record<string, any>;
    public errorMessage: string;

    constructor() {
        this.errors = {};
        this.errorMessage = "";
    }

    public setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    public clearErrorMessage() {
        this.errorMessage = "";
    }

    public get isValid(): boolean {
        return Object.keys(this.errors).length === 0;
    }

    public async validateModel() {
        const result = await validate(this);
        this.errors = this.setError(result);
    }

    private setError(result: ValidationError[]): Record<string, any> {
        const propBag = {};

        for (const error of result) {
            for (const key in error.constraints) {
                if (Object.prototype.hasOwnProperty.call(error.constraints, key)) {
                    (propBag as any)[error.property] = error.constraints;
                }
            }
        }
        return propBag;
    }
}