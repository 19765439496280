
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import InfoItem from "@/components/infoitem.vue";

export default defineComponent({
  name: "NewsView",
  components: { InfoItem },
  setup() {
    const store = useStore();
    const items = computed(() => store.getters.loadedNews);

    onMounted(() => {
      store.dispatch("loadNews"); //If not loaded in onmounted the component for the indicator is not working
    });

    return {
      items,
      InfoItem,
    };
  },
});
