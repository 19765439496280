<template>
  <div class="inline-text-field-container">
    <label
      ref="refInputText"
      class="mdc-text-field mdc-text-field--outlined"
      :class="mdcInputType"
    >
      <span
        class="mdc-notched-outline mdc-notched-outline--upgraded"
        :class="mdcNotchedOutline"
      >
        <span class="mdc-notched-outline__leading"></span>
        <span class="mdc-notched-outline__notch" :style="notchWidth">
          <span
            ref="refMdcFloatingLabel"
            @animationend="anitmationend"
            v-if="label"
            class="mdc-floating-label"
            :class="mdcFloatingLabel"
            >{{ label }}</span
          >
        </span>
        <span class="mdc-notched-outline__trailing"></span>
      </span>
      <input
        ref="refInput"
        :type="inputtype"
        aria-labelledby="my-label-id"
        :value="modelValue"
        @input="updateValue"
        :disabled="type.includes('disabled')"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        :min="min"
        :max="max"
        :pattern="pattern"
        @focus="focusInput($event, true)"
        @blur="focusInput($event, false)"
        :class="mdcInputStyle"
      /><span></span>
    </label>
    <div class="mdc-text-field-helper-line">
      <div
        v-if="valid"
        class="mdc-text-field-helper-text"
        :class="{ 'mdc-text-field-helper-text--persistent': permanent }"
        aria-hidden="true"
      >
        {{ helpertext }}
      </div>
      <div
        v-if="!valid"
        class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
        aria-hidden="true"
      >
        {{ validationtext }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* MDCInputDate (Floating action button)
 * Usage: <mdc-input-text>favorite</mdc-input-text> and <mdc-input-text icon="favorite"></mdc-input-text>
 * Icon name can be supplied by attribute icon or trailing icon inside tags. https://material.io/resources/icons for valid icon names.
 * The trailingicon is a button and you can listen for event: trailingIconClick, it returns reference to the input of the component. eg. you can set .value = "" to clear field.
 * You can supply a label that sets label for input
 * If supplying pre-filled value use type label-floating to skip label animation.
 * Supported inputtype = date, datetime-local, email, month, number, password
 */
import { defineComponent, ref, nextTick } from "vue";

export default defineComponent({
  name: "mdcInputDate",
  props: {
    type: {
      type: String,
      default: "default",
    },
    label: {
      type: String,
      default: null,
    },
    inputtype: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    trailingicon: {
      type: String,
      default: null,
    },
    helpertext: {
      type: String,
      default: null,
    },
    permanent: {
      type: Boolean,
      default: false,
    },
    validationtext: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    cols: {
      type: Number,
      default: 40,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const refInputText = ref(null);
    const refInputTextCounter = ref(null);
    const refInput = ref(null);
    const refMdcFloatingLabel = ref(null);
    const mdcInputType = ref("");
    const mdcNotchedOutline = ref("");
    const mdcFloatingLabel = ref("");
    const mdcInputStyle = ref("");
    const valid = ref(true);
    const notchWidth = ref({});

    let types: string[] = [
      "with-leading-icon",
      "with-trailing-icon",
      "no-label",
      "focused",
      "end-aligned",
      "with-internal-counter",
    ];

    function updateValue(event: MouseEvent & { target: HTMLInputElement }) {
      context.emit("update:modelValue", event.target.value);
    }
    if (props.type.includes("disabled")) {
      mdcInputType.value += " mdc-text-field--disabled";
    }

    if (!props.label) {
      mdcInputType.value += " mdc-text-field--no-label";
    }

    if (props.type.includes("float-above")) {
      mdcFloatingLabel.value = "mdc-floating-label--float-above mdc-float-hack";
      mdcInputType.value += " mdc-text-field--label-floating";
    }

    function trailingIconClick() {
      context.emit("trailingIconClick", <HTMLInputElement>refInput.value);
    }

    function focusInput(
      event: MouseEvent & { target: HTMLInputElement },
      action: boolean
    ) {
      if (action == true) {
        console.log("In action true");
        mdcFloatingLabel.value = "mdc-floating-label--float-above";
        if (mdcInputType.value.indexOf("mdc-text-field--label-floating") < 0) {
          mdcInputType.value += " mdc-text-field--label-floating";
        }
        if (mdcInputType.value.indexOf("mdc-text-field--focused") < 0) {
          mdcInputType.value += " mdc-text-field--focused";
        }

        mdcNotchedOutline.value = "mdc-notched-outline--notched";
        mdcInputStyle.value = "mdc-input-date-hack";
        notchWidth.value = {
          width: refMdcFloatingLabel.value.clientWidth * 0.75 + 8 + "px",
        };
        console.log(notchWidth.value);
      } else {
        if (event.target.value === "" && event.target.checkValidity()) {
          mdcFloatingLabel.value = "";
          mdcInputType.value = ""; //mdcInputType.value.replace("mdc-text-field--label-focused mdc-text-field--label-floating", "");
          mdcInputStyle.value = "";
          mdcNotchedOutline.value = "";
          notchWidth.value = {};
        }
        console.log("Before - Validitycheck " + event.target.checkValidity());
        if (!event.target.checkValidity()) {
          if (mdcInputType.value.indexOf("mdc-text-field--invalid") < 0) {
            mdcInputType.value += " mdc-text-field--invalid";
            mdcFloatingLabel.value += " mdc-floating-label--shake";
            valid.value = false;
          } else {
            mdcFloatingLabel.value += " mdc-floating-label--shake";
          }
        } else {
          mdcInputType.value = mdcInputType.value.replace(
            "mdc-text-field--invalid",
            ""
          );
          valid.value = true;
        }
        mdcInputType.value = mdcInputType.value.replace(
          "mdc-text-field--focused",
          ""
        );
      }
    }

    function anitmationend(event) {
      console.log(event);
      mdcFloatingLabel.value = mdcFloatingLabel.value.replace(
        "mdc-floating-label--shake",
        ""
      );
    }

    return {
      refInputText,
      refInputTextCounter,
      refInput,
      refMdcFloatingLabel,
      mdcInputType,
      mdcNotchedOutline,
      mdcFloatingLabel,
      trailingIconClick,
      updateValue,
      focusInput,
      mdcInputStyle,
      valid,
      anitmationend,
      notchWidth,
    };
  },
});
</script>

<style scoped>
.inline-text-field-container {
  display: flex;
  flex-direction: column;
}

input {
  height: 100%;
  caret-color: var(--mdc-theme-primary, #d0c4a4);
  display: flex;
  border: none !important;
  background-color: transparent;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}

.mdc-input-date-hack {
  outline: none;
  opacity: 1;
  /* border-color: --primary; */
}

/* .mdc-float-hack {
  padding: 0px 2px 0px 2px;
  background-color: white;
} */

input:not(focus) {
  opacity: 0;
}
</style>
