<template>
  <div class="" :class="{ editPopout: editPopout }">
    <div class="mdc-card margin" :class="{ 'margin-edit': editPopout }">
      <div v-if="showEditor" class="editBar">
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Rensa formatering"
          @click="setCommand('removeFormat'); setCommand('formatblock','div');"
        >
          format_clear
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Fet"
          @click="setCommand('bold')"
        >
          format_bold
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Kursiv"
          @click="setCommand('italic')"
        >
          format_italic
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Understrykning"
          @click="setCommand('underline')"
        >
          format_underline
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Titel"
          @click="setCommand('formatblock','h3')"
        >
          format_size
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Justera vänster"
          @click="setCommand('justifyLeft')"
        >
          format_align_left
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Centrera"
          @click="setCommand('justifyCenter')"
        >
          format_align_center
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Justera höger"
          @click="setCommand('justifyRight')"
        >
          format_align_right
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Punktlista"
          @click="setCommand('insertUnorderedList')"
        >
          format_list_bulleted
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Numrerad lista"
          @click="setCommand('insertOrderedList')"
        >
          format_list_numbered
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Minsa indrag"
          @click="setCommand('outdent')"
        >
          format_indent_decrease
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Öka indrag"
          @click="setCommand('indent')"
        >
          format_indent_increase
        </button>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Skapa länk"
          @click="setCommand('createlink', 'https://www.google.com')"
        >
          insert_link
        </button>
        <label v-wave class="mdc-icon-button material-icons" title="Länka foto">
          insert_photo
          <input style="display: none" type="file" @change="handleImage" />
        </label>
        <button
          v-wave
          class="mdc-icon-button material-icons"
          title="Fullskärm"
          @click="editPopout = !editPopout"
        >
          fullscreen
        </button>
        <!-- <button class="mdc-icon-button material-icons" title="Skapa tabell" @click="setInsertTable"> table_chart </button> -->
        <!-- <button class="mdc-icon-button material-icons" title="Klipp ut"> content_cut </button>
        <button class="mdc-icon-button material-icons" title="Kopiera"> content_copy </button>
        <button class="mdc-icon-button material-icons" title="Klistra in"> content_paste </button> -->
        <hr />
      </div>
      <div :id="item.id">
        <div>
          <button
            v-wave
            v-if="item.info_edit_allowed && !showEditor"
            class="edit mdc-icon-button material-icons"
            title="Ändra"
            @click="makeContentEditable($event)"
          >
            edit
          </button>
        </div>
        <div
          ref="infoContent"
          :class="{ 'card-content-edit': showEditor }"
          class="card-content"
          :contentEditable="showEditor"
          v-on:paste="pasteOverride"
          v-html="item.info_content"
        ></div>
      </div>
      <div v-if="showEditor">
        <hr />
        <button
          v-wave
          class="margin mdc-button mdc-button--raised"
          @click="saveNewsEdit(item.id, $event)"
          title="Spara"
        >
          <div class="mdc-button__ripple"></div>
          <i class="material-icons mdc-button__icon" aria-hidden="true">save</i>
          <span class="mdc-button__label">Spara</span>
        </button>
        <button
          v-wave
          class="margin mdc-button mdc-button--outlined"
          @click="cancelNewsEdit(item.id, $event)"
          title="Avbryt"
        >
          <div class="mdc-button__ripple"></div>
          <i class="material-icons mdc-button__icon" aria-hidden="true"
            >cancel</i
          >
          <span class="mdc-button__label">Avbryt</span>
        </button>
      </div>
    </div>
    <div ref="inputDialog" class="mdc-dialog">
      <div class="mdc-dialog__container">
        <div
          class="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="my-dialog-title"
          aria-describedby="my-dialog-content"
        >
          <h2 class="mdc-dialog__title" id="my-dialog-title">
            Skapa länk
          </h2>
          <div class="mdc-dialog__content" id="my-dialog-content">
            <label
              class="inputText mdc-text-field mdc-text-field--outlined"
              ref="inputText"
            >
              <span class="mdc-notched-outline">
                <span class="mdc-notched-outline__leading"></span>
                <span class="mdc-notched-outline__notch">
                  <span class="mdc-floating-label" id="my-label-id">{{
                    linkText
                  }}</span>
                </span>
                <span class="mdc-notched-outline__trailing"></span>
              </span>
              <input
                type="text"
                class="mdc-text-field__input"
                aria-labelledby="my-label-id"
              />
            </label>
          </div>
          <div class="mdc-dialog__actions">
            <button
              type="button"
              class="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="cancel"
            >
              <div class="mdc-button__ripple"></div>
              <span class="mdc-button__label">Cancel</span>
            </button>
            <button
              type="button"
              class="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="link"
            >
              <div class="mdc-button__ripple"></div>
              <span class="mdc-button__label">Link</span>
            </button>
          </div>
        </div>
      </div>
      <div class="mdc-dialog__scrim"></div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  PropType,
  // reactive,
  ref,
  // toRefs,
  onMounted,
} from "vue";
import { InfoItem } from "@/models";
// import { useStore } from "vuex";

import { MDCTextField } from "@material/textfield";
import { MDCDialog } from "@material/dialog";

import resizeImage from "@/functions/resizeimage";

interface detail {
  action: string;
}

export default defineComponent({
  name: "infoItem",
  props: {
    item: Object as PropType<InfoItem>,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // const store = useStore();

    const item = props.item;

    let showEditor = ref<boolean>(false);
    const linkText = ref<string>("");
    const inputDialog = ref(null);
    const inputText = ref(null);
    const infoContent = ref(null);
    const editPopout = ref<boolean>(false);

    let inputDialogObj: MDCDialog;
    let textField: MDCTextField;
    let oldRange: Range;

    let oldContent: string = "";

    onMounted(() => {
      inputDialogObj = new MDCDialog(inputDialog.value);
      textField = new MDCTextField(inputText.value);
      inputDialogObj.listen(
        "MDCDialog:closed",
        (action: CustomEvent & { detail: detail }) => {
          if (
            action.detail.action === "close" ||
            action.detail.action === "cancel"
          ) {
            return;
          } else {
            restoreSelection();
            document.execCommand("createlink", false, textField.value);
          }
        }
      );
      if (props.edit === true) {
        makeContentEditableProps(infoContent.value);
      }
    });

    async function handleImage(event: Event & { target: HTMLInputElement }) {
      const selectedImage = event.target.files[0];
      if (!selectedImage.type.match(/image.*/)) {
        return;
      }
      const config = {
        file: selectedImage,
        maxSize: 500,
      };
      const resizedImage: string = await resizeImage(config);
      document.execCommand("insertImage", false, resizedImage);
      event.target.value = ""; //Clear uploaded image
    }

    async function makeContentEditable(
      event: MouseEvent & { target: Element }
    ) {
      let target = event.target.parentNode.firstChild;
      if (target !== null) {
        showEditor.value = true;
        await nextTick(); //Vänta på att sidan rendreras om så att vi kan fokusera på rutan
        let selection = window.getSelection();
        let range = document.createRange();
        range.setStart(infoContent.value, 0);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        oldContent = infoContent.value.innerHTML;
      }
    }

    async function makeContentEditableProps(target) {
      if (target !== null) {
        showEditor.value = true;
        await nextTick(); //Vänta på att sidan rendreras om så att vi kan fokusera på rutan
        let selection = window.getSelection();
        let range = document.createRange();
        range.setStart(infoContent.value, 0);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        oldContent = infoContent.value.innerHTML;
      }
    }

    function saveNewsEdit(param, event) {
      showEditor.value = false;
      editPopout.value = false;

      context.emit("save", {
        id: item.id,
        title: item.title,
        info_type: item.info_type,
        info_datetime: new Date().toISOString(),
        info_content: infoContent.value.innerHTML,
        info_edit_allowed: true,
      });

      // store.dispatch("updateNews", {
      //   id: item.id,
      //   id: item.id,
      //   info_type: "blog",
      //   info_datetime: new Date().toISOString(),
      //   info_content: infoContent.value.innerHTML,
      //   info_edit_allowed: true,
      // });
    }

    function cancelNewsEdit(param, event) {
      showEditor.value = false;
      editPopout.value = false;
      infoContent.value.innerHTML = oldContent;
      context.emit("save");
    }

    function setCommand(param, options?) {
      if (param == "createlink") {
        saveSelection();
        linkText.value = "Länk (URL)";
        inputDialogObj.open();
        return;
      }
      document.execCommand(param, false, options);
    }

    function saveSelection() {
      oldRange = window.getSelection().getRangeAt(0);
    }

    function restoreSelection() {
      let newselection = window.getSelection();
      newselection.removeAllRanges();
      newselection.addRange(oldRange);
    }

    const styles = {
      styles: {
        type: String,
        required: true,
      },
    };

    function pasteOverride(event) {
      // console.log(event.clipboardData.types);
      if (event.clipboardData.types.includes("text/html")) {
        let paste = event.clipboardData.getData("text/html");
        if (paste.indexOf("urn:schemas-microsoft-com:office:excel") >= 0) {
          //Add support for paste from Excel
          let css_class: [string] = paste.match(/[^{]*\{([^}]*)*}/g); //split up css classes into arrays (also other stuff is included that have the same pattern)
          css_class.forEach((element) => {
            if (element.indexOf(".xl") >= 0) {
              let tmp = element.trim().replace(/\r\n/g, ""); //trim and remove all crlf from string
              let classname = tmp.substr(1, tmp.indexOf("\t") - 1); //get class name
              let cssdata = tmp.match(/[^{{]+(?=\})/g); //get CSS data inside brackets
              paste = paste.replace(
                "class=" + classname + " style='", //remove class data and style
                "style='" + cssdata //insert only style + cssdata from class
              );
              paste = paste.replace(
                "class=" + classname, //remove class data if cell has no style
                "style='" + cssdata + "'" //insert only style + cssdata from class
              );
            }
          });
          document.execCommand("insertHTML", false, paste);
          event.preventDefault();
        }
      } else if (event.clipboardData.types.includes("text/plain")) {
        let paste = event.clipboardData.getData("text/plain");
        if (paste.search(/^https?\:\/\//i) == 0) {
          //look for a string beginning with http:// or https:// and convert it to a hyperlink instead of plaintext
          document.execCommand(
            "insertHTML",
            false,
            "<a href='" + paste + "'>" + paste + "</a>"
          );
          event.preventDefault();
        }
        const emailregx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
        if (emailregx.test(paste)) {
          //find email adress in text and add hyperlink mailto:
          let email = paste.match(emailregx);
          let newstring = paste.replace(
            emailregx,
            "<a href='mailto:" + email + "'>" + email + "</a>"
          );
          document.execCommand("insertHTML", false, newstring);
          event.preventDefault();
        }
      }
    }
    return {
      item,
      makeContentEditable,
      showEditor,
      saveNewsEdit,
      cancelNewsEdit,
      setCommand,
      inputText,
      linkText,
      inputDialog,
      pasteOverride,
      handleImage,
      editPopout,
      infoContent,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-content {
  padding: 20px;
  // max-width: 600px;
}

.card-content-edit {
  background-color: #f8f6f1;
  // max-height: 60vh;
  overflow: auto;
  flex: 1;
}

.margin {
  margin: 10px;
  margin-bottom: 20px;
}

.margin-edit {
  flex: 1;
  overflow: auto;
}

.edit {
  top: -10px;
  right: -10px;
  position: absolute;
}

.inputText {
  margin-top: 10px !important;
}

.editPopout {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: var(--primary-dark);
  height: 100vh;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fullscreen {
  float: right;
  top: -6px;
}
</style>
