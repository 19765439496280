<template>
  <div ref="refMdcSnackbar" class="mdc-snackbar">
    <div class="mdc-snackbar__surface" role="status" aria-relevant="additions">
      <div class="mdc-snackbar__label" aria-atomic="false">
        <slot></slot>
      </div>
      <div class="mdc-snackbar__actions" aria-atomic="true">
        <button type="button" class="mdc-button mdc-snackbar__action">
          <div class="mdc-button__ripple"></div>
          <span class="mdc-button__label">{{ buttonlabel }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* MDCSnackbar (Floating action button)
 * Usage: <mdc-snackbar buttonlabel="retry" @reason="handleevent">Snackbar text message</mdc-snackbar>
 * A event called reason is sent when the snackbar is closed.
 *  A string with "action" is sent if the user dismisses the snackbar with the button
 *  A string with "dismiss" is sent if the snackbar closes by timeout
 *  It can also return undefined but i have no idea when it could happen
 *
 * It can also be extended with a dismiss button but i don't know if we need it
 */
// In lövstaskytte use to create snack message, messages are queued aftwer eachother to diplay only one message after eachother.
//      store.dispatch("createSnack", {labelText: "Bar is open!", actionButtonText: "Great" });

import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { MDCSnackbar } from "@material/snackbar";

interface detail {
  reason: string;
}

export default defineComponent({
  name: "mdcSnackbar",
  props: {
    buttonlabel: {
      type: String,
      default: null,
    },
  },
  setup(_props, context) {
    const refMdcSnackbar = ref(null);
    let snackbar: MDCSnackbar;
    const store = useStore();
    const snacks = computed(() => store.getters.snacks);
    // const indicatorKey = ref(false);
    store.watch(
      (state: any) => state.snacks,
      () => {
        if (store.getters.snacks.length > 0) {
          snackbar.labelText = store.getters.snacks[0].labelText;
          snackbar.actionButtonText = store.getters.snacks[0].actionButtonText;
          snackbar.open();
        }
      },
      { deep: true }
    );

    const open = () => {
      snackbar.open();
    };

    onMounted(() => {
      snackbar = new MDCSnackbar(refMdcSnackbar.value);
      snackbar.listen(
        "MDCSnackbar:closed",
        (action: CustomEvent & { detail: detail }) => {
          context.emit("reason", action.detail.reason);
          store.dispatch("removeSnack");
          //TODO: Where to handle reload of serviceworker, APP via reason or here?
          // if (
          //   action.detail.action === "close" ||
          //   action.detail.action === "cancel"
          // ) {
          //   return;
          // } else {
          //   return;
          // }
        }
      );
    });

    return { refMdcSnackbar, open, snacks };
  },
});
</script>

<style lang="sass" scoped></style>
