import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "@/router";

import "./styles/main.scss";

import AppConfig from "./config";

import store from "@/stores/store";

import VWave from "v-wave";

import googleAuth from "@/services/auth/google";
import facebookAuth from "@/services/auth/facebook";
import mdcButton from "@/components/UI/mdcButton.vue";
import mdcIconButton from "@/components/UI/mdcIconButton.vue";
import mdcFab from "@/components/UI/mdcFab.vue";
import mdcList from "@/components/UI/mdcList.vue";
import mdcDrawer from "@/components/UI/mdcDrawer.vue";
import mdcIndicator from "@/components/UI/mdcIndicator.vue";
import mdcInputText from "@/components/UI/mdcInputText.vue";
import mdcDialog from "@/components/UI/mdcDialog.vue";
import mdcCheckbox from "@/components/UI/mdcCheckbox.vue";
import mdcRadiobutton from "@/components/UI/mdcRadiobutton.vue";
import mdcSwitch from "@/components/UI/mdcSwitch.vue";
import mdcSnackbar from "@/components/UI/mdcSnackbar.vue";
import mdcInputDate from "@/components/UI/mdcInputDate.vue";
import mdcDataTable from "@/components/UI/mdcDataTable.vue";
import mdcSelect from "@/components/UI/mdcSelect.vue";

const app = createApp(App, {});
app.component("mdcButton", mdcButton);
app.component("mdcIconButton", mdcIconButton);
app.component("mdcFab", mdcFab);
app.component("mdcList", mdcList);
app.component("mdcDrawer", mdcDrawer);
app.component("mdcIndicator", mdcIndicator);
app.component("mdcInputText", mdcInputText);
app.component("mdcDialog", mdcDialog);
app.component("mdcCheckbox", mdcCheckbox);
app.component("mdcRadiobutton", mdcRadiobutton);
app.component("mdcSwitch", mdcSwitch);
app.component("mdcSnackbar", mdcSnackbar);
app.component("mdcInputDate", mdcInputDate);
app.component("mdcDataTable", mdcDataTable);
app.component("mdcSelect", mdcSelect);
app.use(router);
app.use(store);
app.use(VWave);
app.use(googleAuth, {
  clientId: AppConfig.GoogleClientId,
  prompt: "select_account",
});
app.use(facebookAuth, {
  appId: AppConfig.FacebookAppId,
});

AppConfig.ensureSettings();

app.config.globalProperties.$filters = {
  formatDate(date: string): string | null {
    if (date) {
      return new Date(date).toLocaleDateString("sv-SE");
    }
    return null;
  },
};

app.mount("#app");
