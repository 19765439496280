<template>
  <info-item v-if="item" :item="item" :edit="edit" @save="saveItem"></info-item>
  <div class="fabcontain" v-if="!item">
    <mdc-fab class="addfab" @click="addPage">add</mdc-fab>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import InfoItem from "@/components/infoitem.vue";

export default defineComponent({
  name: "InfoView",
  components: { InfoItem },
  setup() {
    const edit = ref(false);
    const store = useStore();
    const route = useRoute();
    const shortName = computed(() => route.params.shortName);
    store.dispatch("loadInfoPage", route.params.shortName); //If not loaded in onmounted the component for the indicator is not working
    const item = computed(() =>
      store.getters.loadedInfoPages(route.params.shortName)
    );

    // fetch the user information when params change
    watch(
      () => route.params,
      async (newParams) => {
        if (newParams.shortName) {
          store.dispatch("loadInfoPage", newParams.shortName);
        }
      }
    );

    function addPage() {
      store.dispatch("createInfoPage", {
        title: route.params.shortName.toString().toUpperCase(),
      });
      edit.value = true;
    }

    function saveItem(infoPage) {
      edit.value = false;
      if (infoPage) {
        store.dispatch("updateInfoPage", infoPage);
      }
    }

    // onMounted(() => {

    // });

    return {
      item,
      InfoItem,
      shortName,
      addPage,
      edit,
      saveItem,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// $primary: #d0c4a4;

.container {
  // padding-left: 20px;
  // padding-right: 20px;
  padding: 10px;
}

.fabcontain {
  align-items: flex-end;
  display: flex;
  height: 100px;
}

.addfab {
  right: 16px;
  bottom: 16px;
  position: absolute;
}
</style>
