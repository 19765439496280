<template>
  <button
    v-wave
    class="mdc-fab"
    :class="mdcFabType"
    :aria-label="label"
    :title="label"
  >
    <div class="mdc-fab__ripple"></div>
    <span class="mdc-fab__icon material-icons"
      ><slot>{{ icon }}</slot></span
    >
    <span v-if="label" class="mdc-fab__label">{{ label }}</span>
  </button>
</template>

<script lang="ts">
/* MDCFab (Floating action button)
 * Usage: <mdc-fab>favorite</mdc-fab> and <mdc-fab icon="favorite"></mdc-fab>
 * Iconname can be supplied either by attribute or text inside tags. https://material.io/resources/icons for valid icon names.
 * The button supports three modes (default, mini, extended). Default is default style if omitted.
 * When using extended type a labeltext should be supplied
 * <mdc-fab type="extended" label="Add to cart">add</mdc-fab>
 */
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "mdcFab",
  props: {
    type: {
      type: String,
      default: "default",
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const mdcFabType = ref("");

    if (props.type === "mini" || props.type === "extended") {
      mdcFabType.value = "mdc-fab--" + props.type;
    } else {
      mdcFabType.value = "";
    }

    return { mdcFabType };
  },
});
</script>

<style lang="sass" scoped></style>
