import Vue, { App, } from "vue";

declare global {
    interface Window { gapi: any; }
}

declare module "*.vue" {
    interface Vue {
        $googleAuth: IGoogleAuthentication
    }
}

window.gapi = window.gapi || {};

export interface IGoogleAuthentication {
    load(config: IGoogleAuthConfig, prompt: string): void;
    signIn(successCallback: Function | undefined, errorCallback: Function | undefined): Promise<boolean | string>;
    getAuthCode(successCallback: Function | undefined, errorCallback: Function | undefined): Promise<string | boolean>;
    signOut(successCallback: Function | undefined, errorCallback: Function | undefined): Promise<string | boolean>;
}

export interface IGoogleAuthConfig {
    scope: string | null;
    discoveryDocs: string[];
    prompt: string | null;
    clientId: string | null;
}

const googleAuth = (): IGoogleAuthentication => {


    const installClient = (): Promise<void> => {
        var apiUrl: string = "https://apis.google.com/js/api.js";
        return new Promise((resolve) => {
            var script: HTMLScriptElement = document.createElement("script");
            script.src = apiUrl;

            document.onreadystatechange = script.onload = (ev: Event) => {
                if (document.readyState !== "complete") {
                    setTimeout(() => {
                        resolve();
                    }, 500);
                }
            };

            document.getElementsByTagName("head")[0].appendChild(script);
        })
    };

    const initClient = (config: IGoogleAuthConfig): Promise<void> => {
        return new Promise((resolve) => {

            window.gapi.load("auth2", () => {
                window.gapi.auth2.init(config)
                    .then(() => {
                        resolve(window.gapi);
                    });
            });
        });
    };

    const Auth = (): IGoogleAuthentication => {

        let googleAuth: any | null = null; /* window.gapi.auth2.getAuthInstance() */
        let isAuthorized: boolean = false;
        let isInit: boolean = false;
        let authPrompt: string | null = null;

        const load = (config: IGoogleAuthConfig, prompt: string): void => {

            installClient().then(() => {
                return initClient(config);
            }).then((gapi: any) => {
                googleAuth = gapi.auth2.getAuthInstance();
                isInit = true;
                authPrompt = prompt;
                isAuthorized = googleAuth.isSignedIn.get();
            });

        };

        const signIn = (successCallback: Function, errorCallback: Function): Promise<boolean | string> => {
            return new Promise((resolve, reject) => {
                if (!googleAuth) {
                    errorCallback(false);
                    reject(false);
                }
                googleAuth.signIn().then(googleUser => {
                    if (typeof successCallback === 'function') successCallback(googleUser);
                    isAuthorized = googleAuth.isSignedIn.get();
                    const idToken = googleAuth.currentUser.get().getAuthResponse(true).id_token;
                    resolve(idToken);
                }).catch(error => {
                    if (typeof errorCallback === 'function') errorCallback(error);
                    reject(error);
                });
            });
        };

        const signOut = (successCallback: Function, errorCallback: Function): Promise<string | boolean> => {
            return new Promise((resolve, reject) => {
                if (!googleAuth) {
                    errorCallback(false);
                    reject(false);
                }

                googleAuth.signOut()
                    .then(() => {
                        if (typeof successCallback === 'function') successCallback();
                        resolve(true);
                    })
                    .catch((error) => {
                        if (typeof errorCallback === 'function') errorCallback(error);
                        reject(error);
                    })
            });
        };

        const getAuthCode = (successCallback: Function, errorCallback: Function): Promise<string | boolean> => {
            return new Promise((resolve, reject) => {
                if (!googleAuth) {
                    errorCallback(false);
                    reject(false);
                }

                googleAuth.grantOfflineAccess({ prompt: authPrompt })
                    .then((resp) => {
                        if (typeof successCallback === 'function') successCallback(resp.code);
                        resolve(resp.code);
                    })
                    .catch((error) => {
                        if (typeof errorCallback === 'function') errorCallback(error);
                        reject(error);
                    });
            });
        };

        return {
            load,
            signIn,
            getAuthCode,
            signOut
        };
    };

    return Auth();


};

const googleAuthPlugin = {

    install: (app: App, options: IGoogleAuthConfig) => {

        if (typeof options !== "object" || options === undefined) {
            throw new Error("googleAuthPlugin: No GoogleAuthConfig provided");
        }

        let googleAuthDefaultConfig: IGoogleAuthConfig = {
            scope: "profile email",
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
            prompt: null,
            clientId: null
        };

        let googleAuthConfig: IGoogleAuthConfig | null = null;

        let prompt: string = "select_account";

        googleAuthConfig = Object.assign(googleAuthDefaultConfig, options);
        if (options.scope) {
            googleAuthConfig.scope = options.scope;
        }
        if (options.prompt) {
            prompt = options.prompt;
        }
        if (!options.clientId) {
            console.warn("clientId is required");
        }

        const authenticator: IGoogleAuthentication = googleAuth();
        authenticator.load(googleAuthConfig, prompt);

        app.provide("googleAuth", options);

        app.component("googleAuth", authenticator);
        app.config.globalProperties.$googleAuth = authenticator;
        app.provide("googleAuth", authenticator);



    }

};

export default googleAuthPlugin;