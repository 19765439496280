
/* MDCTopAppBar
 * Fixed for Lövsta layout no fancy slots etc
 *
 */
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "mdcTopAppBar",
  props: {
    buttonlabel: {
      type: String,
      default: null,
    },
  },
  setup(_props, context) {
    const titleText = ref("Lövsta Skytteförening");
    const reftitletext = ref(null);
    onMounted(() => {
      if (reftitletext.value.scrollWidth > reftitletext.value.clientWidth) {
        titleText.value = "Lövsta Skf";
      } else {
        titleText.value = "Lövsta Skytteförening";
      }
    });

    function toggleDrawer() {
      context.emit("toggleDrawer");
    }

    return { reftitletext, titleText, toggleDrawer };
  },
});
