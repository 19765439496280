<template>
  <header class="mdc-top-app-bar">
    <div class="mdc-top-app-bar__row">
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
      >
        <mdc-icon-button
          class="mdc-top-app-bar__navigation-icon menuIcon"
          @click="toggleDrawer"
          aria-label="Open navigation menu"
          >menu</mdc-icon-button
        >
        <img
          class="top-logo"
          src="/img/logo.svg"
          alt="Lövsta Skytteförenning"
        />
        <span ref="reftitletext" class="mdc-top-app-bar__title">{{
          titleText
        }}</span>
      </section>
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        role="toolbar"
      >
        <!-- <button
          v-wave
          class="material-icons mdc-top-app-bar__action-item mdc-icon-button"
          aria-label="Search"
        >
          search
        </button>
        <button
          v-wave
          class="material-icons mdc-top-app-bar__action-item mdc-icon-button"
          aria-label="Options"
        >
          more_vert
        </button> -->
      </section>
    </div>
  </header>
</template>

<script lang="ts">
/* MDCTopAppBar
 * Fixed for Lövsta layout no fancy slots etc
 *
 */
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "mdcTopAppBar",
  props: {
    buttonlabel: {
      type: String,
      default: null,
    },
  },
  setup(_props, context) {
    const titleText = ref("Lövsta Skytteförening");
    const reftitletext = ref(null);
    onMounted(() => {
      if (reftitletext.value.scrollWidth > reftitletext.value.clientWidth) {
        titleText.value = "Lövsta Skf";
      } else {
        titleText.value = "Lövsta Skytteförening";
      }
    });

    function toggleDrawer() {
      context.emit("toggleDrawer");
    }

    return { reftitletext, titleText, toggleDrawer };
  },
});
</script>

<style lang="sass" scoped></style>
