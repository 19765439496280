<template>
  <div class="mdc-form-field mdc-touch-target-wrapper">
    <div v-wave class="mdc-radio mdc-radio--touch mdc-ripple-border-fix">
      <input
        class="mdc-radio__native-control"
        type="radio"
        :name="group"
        :id="id"
        :value="value"
        :checked="modelValue === value"
        @input="updateValue"
        :disabled="disabled"
      />
      <div class="mdc-radio__background">
        <div class="mdc-radio__outer-circle"></div>
        <div class="mdc-radio__inner-circle"></div>
      </div>
    </div>
    <label :for="id"><slot></slot></label>
  </div>
</template>

<script lang="ts">
/* MDCRadiobutton
 * Usage: <mdc-radiobutton id="uniqueId" name="radiogroup" value="valueofchoice" v-bind="choosenvalue">TextOnRadiobutton</mdc-radiobutton>
 * Id should be supplied to keep label and input connection
 * If you set the choosenvalue v-bind value that is selected in the radiogroup by default. Leave empty to not select any value.
 * If you supply disabled attribute <mdc-radiobutton disabled>TextOnRadiobutton</mdc-radiobutton> the input will be disabled
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "mdcRadiobutton",
  props: {
    group: {
      type: String,
      default: "radios",
    },
    id: {
      type: String,
      default: "checkbox-1",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(_props, context) {
    function updateValue(event: MouseEvent & { target: HTMLInputElement }) {
      context.emit("update:modelValue", event.target.value);
    }

    return { updateValue };
  },
});
</script>

<style lang="sass" scoped></style>
