<template>
  <div
    ref="refMdcLinearProgress"
    role="progressbar"
    class="mdc-linear-progress"
    aria-label="Example Progress Bar"
    aria-valuemin="0"
    aria-valuemax="1"
    aria-valuenow="0"
    :dummy="loading"
  >
    <div class="mdc-linear-progress__buffer">
      <div class="mdc-linear-progress__buffer-bar"></div>
      <div class="mdc-linear-progress__buffer-dots"></div>
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
      <span class="mdc-linear-progress__bar-inner"></span>
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
      <span class="mdc-linear-progress__bar-inner"></span>
    </div>
  </div>
</template>

<script lang="ts">
/* MDCProgressBar
 * Usage: <mdc-indicator>other_html</mdc-indicator>
 * You can send open and close events to the component to open or close it.
 */
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { MDCLinearProgress } from "@material/linear-progress";

export default defineComponent({
  name: "mdcDrawer",
  props: {},
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters.loading);
    // const indicatorKey = ref(false);
    store.watch(
      (state: any) => state.loading,
      () => {
        if (store.getters.loading > 0) {
          linearProgress.open();
          linearProgress.determinate = false;
        } else {
          linearProgress.close();
        }
      },
      { deep: true }
    );

    const refMdcLinearProgress = ref(null);
    let linearProgress: MDCLinearProgress;

    onMounted(() => {
      linearProgress = new MDCLinearProgress(refMdcLinearProgress.value);
      // linearProgress.close();
    });

    return { refMdcLinearProgress, loading };
  },
});
</script>

<style lang="sass" scoped></style>
