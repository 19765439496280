<template>
  <mdc-drawer ref="refSidebar">
    <mdc-list
      v-if="menuitems"
      type="dense router-link"
      :items="menuitems"
    ></mdc-list>
  </mdc-drawer>

  <mdc-top-app-bar @toggleDrawer="toggleDrawer"></mdc-top-app-bar>

  <div
    class="mdc-drawer-app-content mdc-top-app-bar--fixed-adjust main-content"
  >
    <mdc-indicator></mdc-indicator>
    <mdc-snackbar></mdc-snackbar>
    <router-view />
  </div>
  <footer>Lövsta Skytteförening<br />Kontakt | Lovstaskytte.se | © 2021</footer>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import mdcTopAppBar from "./components/UI/mdcTopAppBar.vue";

export default defineComponent({
  components: { mdcTopAppBar },
  name: "App",
  setup() {
    const refSidebar = ref(null);
    const sidebar = reactive({ display: "" });

    const store = useStore();
    store.dispatch("loadMenus");

    const menuitems = computed(() => store.getters.loadedMenus);

    function toggleDrawer() {
      refSidebar.value.open();
    }
    onMounted(() => {
      // store.dispatch("loadNews"); //If not loaded in onmounted the component for the indicator is not working
    });

    return {
      toggleDrawer,
      sidebar,
      refSidebar,
      menuitems,
    };
  },
});
</script>

<style lang="scss"></style>
