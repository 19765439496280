
/* MDCCheckbox
 * Usage: <mdc-checkbox id="uniqueId">TextOnCheckbox</mdc-checkbox>
 * Id should be supplied to keep label and input connection
 * If you supply disabled attribute <mdc-checkbox disabled>TextOnCheckbox</mdc-checkbox> the input will be disabled
 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "mdcCheckbox",
  props: {
    id: {
      type: String,
      default: "checkbox-1",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, context) {
    function updateValue(event: MouseEvent & { target: HTMLInputElement }) {
      context.emit("update:modelValue", event.target.checked);
    }

    return { updateValue };
  },
});
