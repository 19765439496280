
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "mdcTest",
  setup(props, context) {
    const store = useStore();
    const exampleDialog = ref(null);
    const checked = ref(true);
    const radioChoice = ref("choice2");
    const selectChoice = ref("grains");
    const stremail = ref("");
    const switch_1 = ref(true);
    const refSnackbar = ref(null);
    const intNumber = ref(7);
    const inputDate = ref(null);
    const inputDateTime = ref(null);

    function opensnackbar() {
      store.dispatch("createSnack", {labelText: "Bar is open!", actionButtonText: "Great" });
      // refSnackbar.value.open();
    }

    function setLoading(value: boolean) {
      store.commit("setLoading", value);
    }

    function dialogevent(action: string) {
      console.log(action);
    }

    function increment() {
      store.dispatch("loadNews");
    }

    function inputclick(inputText: HTMLInputElement) {
      inputText.value = "";
      inputText.focus();
    }

    function copyclick(inputText: HTMLInputElement) {
      console.log(inputText);
      inputText.select();
      inputText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      //inputText.focus();
    }

    function opendialog() {
      exampleDialog.value.open();
    }
    const select = [
      {
        key: "1",
        text: "Bread, Cereal, Rice, and Pasta",
        value: "grains",
      },
      {
        key: "2",
        text: "Vegetables",
        value: "vegetables",
      },
      {
        key: "3",
        text: "Fruit",
        value: "fruit",
      },
    ];

    const rowsPerPage = [
      {
        key: "1",
        text: "10",
        value: 10,
      },
      {
        key: "2",
        text: "50",
        value: 50,
      },
      {
        key: "3",
        text: "100",
        value: 100,
      },
            {
        key: "4",
        text: "1000",
        value: 1000,
      },
    ];

    const tableColumns = [
      {
        label: "Id",
        id: "id",
        type: "numeric",
        sortable: true,
        key: true,
        display: null,
      },
      {
        label: "Name",
        id: "name",
        sortable: true,
        key: false,
        display: function(row) {
          return (
            '<a href="#" data-id="' +
            row.id +
            '" class="name-btn">' +
            row.name +
            "</button>"
          );
        },
      },
      {
        label: "Email",
        id: "email",
        sortable: false,
        key: false,
        display: function(row) {
          return (
            '<a href="#" data-id="' +
            row.id +
            '" class="name-btn">' +
            row.name +
            "</button>"
          );
        },
      },
    ];

    const sampleData1 = (offst: number, limit: number) => {
      offst = offst + 1;
      let data = [];
      for (let i = offst; i <= limit; i++) {
        data.push({
          id: i,
          name: "TEST"+Math.round(i/10),
          email: "test" + i + "@example.com",
        });
      }
      return data;
    };
    const tableRows = sampleData1(0, 200);

    const data = [
      {
        key: "1",
        text: "Text for list",
        text2: "Text for line 2",
        icon: "favorite",
        imageUrl: "/img/icons/android-chrome-192x192.png",
        disabled: false,
        selected: false,
        activated: false,
        divider: false,
        href: "/",
      },
      {
        key: "2",
        text: "",
        text2: "",
        icon: "",
        imageUrl: "",
        disabled: false,
        selected: false,
        activated: false,
        divider: true,
      },
      {
        key: "3",
        text: "Text for list",
        text2: "Text for line 2",
        icon: "favorite",
        imageUrl: "/img/icons/android-chrome-192x192.png",
        disabled: false,
        selected: false,
        activated: false,
        divider: false,
        href: "/landing",
      },
    ];
    return {
      data,
      select,
      setLoading,
      increment,
      inputclick,
      exampleDialog,
      opendialog,
      dialogevent,
      checked,
      stremail,
      radioChoice,
      switch_1,
      opensnackbar,
      refSnackbar,
      intNumber,
      inputDate,
      inputDateTime,
      copyclick,
      selectChoice,
      rowsPerPage,
      tableColumns,
      tableRows,
    };
  },
});
