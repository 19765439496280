<template>
  <div class="container">
    <h3>Nyheter</h3>
    <info-item
      v-for="item in items"
      :key="item.info_id"
      :item="item"
    ></info-item>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import InfoItem from "@/components/infoitem.vue";

export default defineComponent({
  name: "NewsView",
  components: { InfoItem },
  setup() {
    const store = useStore();
    const items = computed(() => store.getters.loadedNews);

    onMounted(() => {
      store.dispatch("loadNews"); //If not loaded in onmounted the component for the indicator is not working
    });

    return {
      items,
      InfoItem,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$primary: #d0c4a4;

.container {
  // padding-left: 20px;
  // padding-right: 20px;
  padding: 10px;
}
</style>
