import configData from "../appsettings.json";

export default class AppConfig {

    static AppVersion: string = configData.AppVersion;
    static ApiUrl: string = configData.ApiUrl;
    static FacebookAppId: string = configData.Authentication.Facebook.AppId;
    static GoogleClientId: string = configData.Authentication.Google.ClientId;

    static ensureSettings(): void {
        this.isNotEmpty(this.AppVersion, "AppVersion");
        this.isNotEmpty(this.ApiUrl, "ApiUrl");
        this.isNotEmpty(this.FacebookAppId, "FacebookAppId");
        this.isNotEmpty(this.GoogleClientId, "GoogleClientId");
    }

    private static isNotEmpty(variable: any, variableName: string): void {

        if (variable == undefined || variable == null)
            throw `The AppConfig setting '${variableName}' could not be found.`;

        if (typeof (variable) == "string")
            if (variable === "" || variable === "NOT_SET")
                throw `The AppConfig setting '${variableName}' was empty.`;

    }

}