
/* MDCList
 * Usage: <mdc-list>favorite</mdc-list> and <mdc-list icon="favorite"></mdc-list>
 * The button supports multiple modes, can be array of strings for multiple options.
 * ex. (dense, textual-list, avatar-list, icon-list, image-list, thumbnail-list, video-list, two-line, router-link). Default is "mdc-list" style if omitted.
 * Iconname can be supplied by attribute in Items binding "icon" property. https://material.io/resources/icons for valid icon names.
 */
import { defineComponent, ref, PropType, onMounted } from "vue";
import { MDCList } from "@material/list";

interface MDCListItems {
  key: string;
  href?: string;
  text: string;
  text2?: string;
  icon?: string;
  imageUrl?: string;
  disabled?: boolean;
  selected?: boolean;
  activated?: boolean;
  divider?: boolean;
}

export default defineComponent({
  name: "mdcList",
  props: {
    type: {
      type: String,
      default: "",
    },
    items: {
      type: Object as PropType<MDCListItems>,
      default: null,
    },
  },
  setup(props) {
    let refMdcList = ref(null);

    onMounted(() => {
      new MDCList(refMdcList.value);
    });

    const mdcListType = ref("");

    let types: string[] = [
      "dense",
      "textual-list",
      "avatar-list",
      "icon-list",
      "image-list",
      "thumbnail-list",
      "video-list",
      "two-line",
    ];

    types.forEach((type) => {
      if (props.type.includes(type)) {
        mdcListType.value += " mdc-list--" + type;
      }
    });
    if (props.type.includes("two-line")) {
      if (props.type.includes("icon-list")) {
        mdcListType.value = "mdc-list--two-line"; //Fix for icon-lists should not have mdc-list--icon-list class, then icon is not centered on two-lines
      }
    }
    return { mdcListType, refMdcList };
  },
});
