
    import { IFacebookAuthentication } from "@/services/auth/facebook";
    import { IGoogleAuthentication } from "@/services/auth/google";
    import { defineComponent, reactive, computed, onMounted, inject } from "vue";
    import appApi from "@/services/Api";
    import auth from "@/stores/modules/auth";
    import router from "@/router";
    import { IAuthenticationRequest, AuthenticationRequest } from "@/models";

    interface State {
        request: AuthenticationRequest;
    }

    export default defineComponent({
        name: "login",
        inject: ["googleAuth"],
        setup(props, context) {

            const state = reactive<State>({
                request: new AuthenticationRequest()
            });

            const googleAuth: IGoogleAuthentication = inject("googleAuth");
            const facebookAuth: IFacebookAuthentication = inject("facebookAuth");

            const setDefaults = () => {
                state.request.email = "test@test.see";
                state.request.password = "AahU7#!as382h";
            };

            onMounted(setDefaults);

            const login_local = async () => {
                await state.request.validateModel();
                if (!state.request.isValid) return;
                const result = await auth.authenticateUser(state.request);
            };

            const logout_local = async () => {
                await auth.logout();
            };

            const get_google_auth = async () => {

            }

            const get_secure_data = async () => {
                const data = await appApi.getSecretServerInfo();
                console.log(data);
            }

            const get_profile = async () => {
                await auth.fetchProfile();
            }

            const login_google = async () => {
                //googleAuth.getAuthCode(async (code: any) => {
                //    await appApi.authGoogle(code);
                //}, (err: any) => {
                //    console.error(err);
                //});

                var token = await googleAuth.signIn(undefined, undefined);
                await auth.authenticateGoogleUser(token);
                //await auth.fetchProfile();
                
                    //.then((user: any) => {
                    //    console.log("logged in:", user);
                    //    const result = await auth.authenticateGoogleUser(state.request);
                    //    auth.authenticateGoogleUser(user).then(() => {
                    //        //auth.fetchProfile();
                    //    })
                        
                    //})
                    //.catch((err) => {
                    //    console.error(err);
                    //});
            };

            const login_facebook = async () => {
                facebookAuth.login();
            };

            const logout_facebook = async () => {
                facebookAuth.logout();
            };

            return {
                state,
                auth,
                login_google,
                login_facebook,
                logout_facebook,
                login_local,
                logout_local,
                get_profile,
                get_secure_data
            };
        },
        async mounted() {
            //await this.initAuth();
        },
    });
