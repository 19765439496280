<template>
  <div ref="inputDialog" class="mdc-dialog">
    <div class="mdc-dialog__container">
      <div
        class="mdc-dialog__surface"
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="my-dialog-title"
        aria-describedby="my-dialog-content"
      >
        <h2 class="mdc-dialog__title" id="my-dialog-title">
          {{ title }}
        </h2>
        <div class="mdc-dialog__content" id="my-dialog-content">
          <slot></slot>
        </div>
        <div class="mdc-dialog__actions">
          <!-- <button
            v-if="cancel"
            type="button"
            class="mdc-button mdc-dialog__button"
            data-mdc-dialog-action="cancel"
          >
            <div class="mdc-button__ripple"></div>
            <span class="mdc-button__label">{{cancel}}</span>
          </button>
          <button
            v-if="ok"
            type="button"
            class="mdc-button mdc-dialog__button"
            data-mdc-dialog-action="accept"
            data-mdc-dialog-button-default
          >
            <div class="mdc-button__ripple"></div>
            <span class="mdc-button__label">{{ok}}</span>
          </button> -->
          <mdc-button
            v-if="cancel"
            type="outlined"
            class="mdc-dialog__button"
            data-mdc-dialog-action="cancel"
            >{{ cancel }}</mdc-button
          >
          <mdc-button
            v-if="ok"
            type="outlined"
            class="mdc-dialog__button"
            data-mdc-dialog-action="accept"
            >{{ ok }}</mdc-button
          >
        </div>
      </div>
    </div>
    <div class="mdc-dialog__scrim"></div>
  </div>
</template>

<script lang="ts">
/* MDCDialog (Floating action button)
 * Usage: <mdc-dialog>favorite</mdc-dialog> and <mdc-dialog icon="favorite"></mdc-dialog>
 * Iconname can be supplied either by attribute or text inside tags. https://material.io/resources/icons for valid icon names.
 * The button supports three modes (default, mini, extended). Default is default style if omitted.
 * When using extended type a labeltext should be supplied
 * <mdc-dialog type="extended" label="Add to cart">add</mdc-dialog>
 * 
 * //TODO:
 * Ett sätt att hindra dialogen att stänga om man vill göra custom close. Ta bort data-mdc-dialog-action från button. En ny via v-if?
 * dialog_submit.addEventListener('click', () => {
    // if (there is text in the text area) {
        // dialog.close();
    // } else {
        // Give user error message and don't close the dialog
    // }
});
 *  
 */
import { defineComponent, ref, onMounted } from "vue";
import { MDCDialog } from "@material/dialog";

interface detail {
  action: string;
}

export default defineComponent({
  name: "mdcDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    cancel: {
      type: String,
      default: null,
    },
    ok: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const inputDialog = ref(null);
    let inputDialogObj: MDCDialog;

    const open = () => {
      inputDialogObj.open();
    };

    onMounted(() => {
      inputDialogObj = new MDCDialog(inputDialog.value);
      inputDialogObj.listen(
        "MDCDialog:closed",
        (action: CustomEvent & { detail: detail }) => {
          context.emit("action", action.detail.action);
          // if (
          //   action.detail.action === "close" ||
          //   action.detail.action === "cancel"
          // ) {
          //   return;
          // } else {
          //   return;
          // }
        }
      );
    });

    return { inputDialog, open };
  },
});
</script>

<style lang="sass" scoped></style>
